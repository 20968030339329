<template>
  <v-container class="pf-con pf" pa-0>
    <social/>
    <hr>
    <div style="padding-top: 20px;background-color:white; color: darkred;">
    <copyright/>
      <a href="mailto:info@egosoft.az">info@egosoft.az</a><br/>
      <span class="weak">{{ version() }}<size/></span>
    </div>
  </v-container>
</template>

<script>
import Social from "@/ui/atoms/Social.vue";
import Copyright from "@/ui/atoms/Copyright.vue";
import Size from "@/ui/atoms/Size";


export default
  {
  components: {
      Social,
      Copyright,
      Size,

  },
    methods: {
      version: function () {
        let p = require("../../package.json")
        return p.version;
      },
      year: function() {
        let d = new Date();
        return d.getFullYear();
      },
  }  
}

</script>


<style lang="scss" scoped>
@import "@/styles/styles.scss";

.pf-con {
  border-top: 10px $red solid;
  font-weight: 600;
  font-size: small;
  text-align: center;
  background-color: white;
  _color: white;
  _min-height: 200px;
  margin-top: 50px;
  _padding-top: 50px;
}

.pf {
  _font-weight: 600;
  _font-size: small;
  _text-align: center;
  _background-color: $red;
  _color: white;
  _height: 100px;
  _padding-top: 10px;
}

.weak{
  opacity: 0.5;
}
</style>
