import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"soc-con",attrs:{"py-10":""}},[_c(VRow,[_c(VCol,[_c('a',{staticClass:"txt-dec-non",attrs:{"href":"https://www.facebook.com/people/Egosoft-MMC/61557633043259/","target":"blank"}},[_c(VBtn,{attrs:{"fab":""}},[_c(VIcon,{attrs:{"x-large":"","color":"blue darken-4"}},[_vm._v("mdi-facebook")])],1)],1)]),_c(VCol,[_c('a',{staticClass:"txt-dec-non",attrs:{"href":"https://www.instagram.com/egosoft.az/","target":"blank"}},[_c(VBtn,{attrs:{"fab":""}},[_c(VIcon,{attrs:{"x-large":"","color":"red darken-4"}},[_vm._v("mdi-instagram")])],1)],1)]),_c(VCol,[_c('a',{staticClass:"txt-dec-non",attrs:{"href":"https://www.linkedin.com/company/egosoft-az","target":"blank"}},[_c(VBtn,{attrs:{"fab":""}},[_c(VIcon,{attrs:{"x-large":"","color":"blue darken-4"}},[_vm._v("mdi-linkedin")])],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }