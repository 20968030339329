<template>
  <div>
    <v-app-bar-nav-icon @click="toggle"></v-app-bar-nav-icon>
    <v-card id="hm" class="mx-auto">
      <v-navigation-drawer v-if="drawer" permanent v-click-outside="toggle">
        <v-list dense nav>
          <v-list-item
            v-for="(item, i) in menu"
            :key="i"
            link
            :to="item.path"
            @click="toggle"
          >
            {{ item.title[lang] }}
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      drawer: null,
    };
  },
  computed: {
    menu() {
      return this.$store.state.menu;
    },
    lang() {
      return this.$store.state.lang;
    },
  },
  methods: {
    toggle() {
      this.drawer = !this.drawer;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/styles.scss";

#hm {
  position: absolute;
  top: 80px;
}
#hm a {
  font-weight: 600;
  color: $blue;
}

.v-list-item--active {
  font-weight: 600;
  background-color: $red !important;
  color: white !important;
}
</style>