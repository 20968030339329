<template>
  <div class="pt-0 mt-0">
    <page-subtitle>Əsas istifadə etdiyimiz texnologiyalar</page-subtitle>
    <v-container class="tech-con">
      <v-row align-content="center">
        <v-col cols="12" lg="6" sm="6" 
        class="d-flex flex-column my-5" 
        v-for="img in imgs" :key="img.id"
        >
          <v-hover v-slot="{ hover }">
            <v-card
              flat
              class="mx-auto tech-crd"
              :elevation="hover ? 12 : 2"
            >            
            <div v-on:click="openLink(hover, img.link, img.id)">
                <!--a hover :href="img.link" target="_blank"-->
                  <v-btn class="crd-btn-a py-5">{{ img.link }}</v-btn>
              <v-img
                :alt="img.name"                
                :src="hover? require('/src/pages/Technologies/' + img.name + '.png') : require('/src/pages/Technologies/' + img.name +'_cover.png')"
                @click="toggleImg(img.id)"
                rel="preload"
              ></v-img>             
                  <!--/a
                  --></div>
            </v-card></v-hover
          ></v-col></v-row>
          <br>
      <contact-text />
    </v-container>
  </div>
</template>

<script>
import PageSubtitle from "@/ui/atoms/PageSubtitle.vue";
import ContactText from "@/ui/atoms/ContactText.vue";

export default {
  components: {
    PageSubtitle,
    ContactText,
  },
  data() {
    return {
      hover:false,
      iOShover: 0,
      imgs: [
        {
          id: 1,
          name: "dotNet",
          color: "#A82EDA",
          img: false,
          link: "https://dotnet.microsoft.com/",
        },
        {
          id: 3,
          name: "1C",
          color: "#FFE009",
          img: false,
          link: "https://1c.ru/",
        },        {
          id: 2,
          name: "NodeJS",
          color: "#6ABF4B",
          img: false,
          link: "https://nodejs.org/",
        },

        // {
        //   id: 4,
        //   name: "Flutter",
        //   color: "#FFE009",
        //   img: false,
        //   link: "https://flutter.dev/",
        // },
        {
          id: 5,
          name: "php",
          color: "#BEC2F7",
          img: false,
          link: "https://www.php.net/",
        },
      ],
    };
  },
  name: "Technologies",
  metaInfo: {
    title: "texnologiyalar",
    meta: [
      {
        name: "description",
        content:
          "Əsas istifadə etdiyimiz texnologiyalar .Net, Node JS, 1C və php",
      },
    ],
  },

  methods: {
    imgSrc(hover) {
      var src = "/src/pages/Technologies/";
      src = src + hover ? "_cover.png" : "" + ".png";
      return require(src);
    },
    toggleImg(id) {
      this.imgs.forEach((img) => {
        if (img.id == id) {
          img.img = !img.img;
        } else {
          img.img = false;
        }
      });
    },
    openLink (hover, link,id) {      
      if (/iPhone|iPad/i.test(navigator.userAgent)) {
        if (this.iOShover==id){
          window.open(link, "_blank");
          this.iOShover = o;
        } else {
          if (hover) this.iOShover = id;
        }
      } else {
        if (hover) {
          window.open(link, "_blank");
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/styles.scss";
.tech-con {
  padding-top: 50px;
  //padding-left: 10%;
  //padding-right: 10%;
  display: table;
  //width: 100%;
  @media #{map-get($display-breakpoints, 'xl-only')} {
    width: 1980px;
  }
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    width: 1080px;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    
  }

}

.tech-crd {
  display: table-cell;
  text-align: center;  
  
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    width: 600px;
  }
	@media #{map-get($display-breakpoints, 'md-and-up')} {
    width: 480px;
  }
	@media #{map-get($display-breakpoints, 'sm-and-up')} {
    width: 480px;
  }
	@media #{map-get($display-breakpoints, 'xs-only')} {
    width: 280px;
  }
}

.crd-btn-a {
  color: $red;     
  @media #{map-get($display-breakpoints, 'sm-and-down')} 
  {  
    display: none;
  }
  
}

a {
  text-decoration: none !important;
}

</style>