import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo-tmp"},[_c(VContainer,{staticClass:"inf",attrs:{"id":"logo-con"}},[_c(VRow,[_c(VCol,[_c(VChip,{staticClass:"num",attrs:{"x-large":"","label":"","outlined":""}},[_c('span',{attrs:{"id":"prj"}})]),_c('br')],1)],1),_c('div',{staticClass:"lbl"},[_vm._v("layihədə")]),_c('div',[_vm._v("xidmət göstərdik")]),_c('br')],1),_c(VContainer,{staticClass:"logo-img-con"},_vm._l((_vm.imgs),function(img){return _c('span',{key:img},[_c(VImg,{staticClass:"logo-img",attrs:{"aspect-ratio":"1.67","src":require('/src/assets/logos/' + img + '.png'),"alt":img,"rel":"preload"}})],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }