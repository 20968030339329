import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt-0 mt-0"},[_c('page-subtitle',[_vm._v("Əsas istifadə etdiyimiz texnologiyalar")]),_c(VContainer,{staticClass:"tech-con"},[_c(VRow,{attrs:{"align-content":"center"}},_vm._l((_vm.imgs),function(img){return _c(VCol,{key:img.id,staticClass:"d-flex flex-column my-5",attrs:{"cols":"12","lg":"6","sm":"6"}},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c(VCard,{staticClass:"mx-auto tech-crd",attrs:{"flat":"","elevation":hover ? 12 : 2}},[_c('div',{on:{"click":function($event){return _vm.openLink(hover, img.link, img.id)}}},[_c(VBtn,{staticClass:"crd-btn-a py-5"},[_vm._v(_vm._s(img.link))]),_c(VImg,{attrs:{"alt":img.name,"src":hover? require('/src/pages/Technologies/' + img.name + '.png') : require('/src/pages/Technologies/' + img.name +'_cover.png'),"rel":"preload"},on:{"click":function($event){return _vm.toggleImg(img.id)}}})],1)])]}}],null,true)})],1)}),1),_c('br'),_c('contact-text')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }