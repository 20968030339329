<template>
  <div class="pt-0 mt-0">
    <product-cards />
    <page-space />
  </div>
</template>

<script>
import ProductCards from "@/pages/Products/ProductCards.vue";
import PageSpace from "@/ui/atoms/PageSpace.vue";

export default {
  name: "Products",
  metaInfo: {
    title: "məhsullar",
    meta: [
      {
        name: "description",
        content:
          "egosoft veritas 2 － 1C 8.3 platformasında sadə və universal uçot sistemi",
      },
    ],
  },

  components: { ProductCards, PageSpace },
  mounted() {},

  methods: {},
};
</script>
