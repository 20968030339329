<template>  
  <div class="sub">
    <v-container class="txt-blc-con">
      <div style="display: flex; justify-content: center">
        <v-card elevation="10">
          <v-img
            alt="IS Outsourcing"
        :src="require('/src/pages/Services/ISOutsourcing/ISOutsourcing.png')"
            class="pag-img"
          ></v-img
        ></v-card>
      </div>
      <br />      
      <p>
        <txt-emp>Outsourcing</txt-emp> adətən şirkətlərin daxili resursları hesabına görülən işlərin kənar təşkilata həvalə edilməsidir.</p>
        <p><h2>Outsourcing biznes sahiblərinə aşağıdakı üstünlükləri verir:</h2>
      </p>
      <p>
        <txt-emp>Ekonomiya</txt-emp>: outsourcing əməliyyat xərclərini azaldır. İnformasiya Sistemləri ilə əlaqədar işlərin ixtisaslaşmış şirkət tərəfindən yerinə yetirilməsi halında siz yüksək kvalifikasiyalı işçinin tam zamanını almaq yerinə mütəxəssisin zamanının yalnız sizə lazım olan hissəsini ödəyərək əmək haqqı ilə əlaqədar xərclərinizi azaltmış olursunuz. 
      </p>
      <p>
        <txt-emp>Kvalifikasiya</txt-emp>: bir çox halda yüksək ixtisaslı texniki mütəxəssislər onlar üçün maraqlı olan texnologiya sahələrində fəaliyyət göstərən müəssisələrdə işləməyə üstünlük verirlər və qeyri-texniki şirkətlər üçün belə kvalifikasiyalı işçilər əlçatan olmaya bilər. Outsourcing daha yüksək kvalifikasiyalı texniki mütəxəssisin xidmətlərindən yararlanma imkanı verir.
      </p>
      <p>
        <txt-emp>Effektivlik</txt-emp>: şirkətin əsas fəaliyyət sahəsindən kənarda olan işlərin outsource edilməsi əsas fəaliyyətlərə daha çox fokuslanma və əsas fəaliyyətin effektivliyini artırma imkanı verir. Eyni zamanda informasiya sistemləri ilə əlaqədar işlərin texniki kadrların idarəedilməsi səriştəsinə sahib olan təşkilat tərəfindən aparılması bu mütəxəssislərin işinin də effektivliyini artırır.
      </p>
      <p>
        <txt-emp>Çeviklik</txt-emp>: outsourcing işçi ehtiyacı dəyişikliklərinə daha rahat və çevik cavab verə bilmək deməkdir. Ehtiyac halında qısa müddət üçün də bir mütəxəssisin bilik və bacarıqlarından faydalana bilirsiniz. Şirkətin öz İS şöbəsindən fərqli olaraq IS Outsourcing halında biznesin vəziyyətindən asılı olaraq işçi resurslarınızı sürətlə artıra və ya azalda bilirsiniz. Bu sizin bazar dəyişikliklərinə daha çevik cavab vermənizi təmin edir, və sizi işçi axtarma, təlim və resursları azaltma proseslərindən azad edir.
      </p>
      <p>
        <txt-emp>Texnologiya və infrastruktur</txt-emp>: outsourcing çox vaxt daxili imkanlarla baha başa gələn və ya qeyri-mümkün olan yüksək texnologiya və infrastrukturdan istifadə edə bilmək imkanı deməkdir. Bu halda siz heç bir investisiya etmədən inkişaf etmiş sistemdən faydalana bilirsiniz.
      </p>
      <p>
        <txt-emp>Fokus</txt-emp>: əsas biznes fəaliyyəti kənarında olan əməliyyatların outsourcingi sizə əsas rəqabət gücünə sahib olmalı olduğunuz sahələrə daha çox fokuslanma imkanı verir. Köməkçi proseslərin xarici resurslar vasitəsi ilə idarə edilməsi halında təşkilatlar rəqabət gücünə birbaşa təsir edən fəaliyyətlər üçün daha çox zaman, resurs və enerji ayıra bilir.
      </p>
      <p>
        <txt-emp>Risklərin azaldılması</txt-emp>: outsourcing bəzi biznes risklərinin azaldılmasına kömək edir, çünki müəyyən funksionalı outsource etməklə siz əlaqəli riskləri də outsourcing şirkətinə ötürmüş olursunuz.
      </p>
      <v-card elevation="10">
        <v-card-text>
          <h2>İnformasiya sistemlərinin outsourcinginin planlanması, qurulması və dəstəyi üçün lazım olan bütün məsələlərin koordinasiya və nəzarəti üçün İnformasiya Sistemləri Direktoru (CIO) səlahiyyətlərini biz icra edirik. Sizin digər şöbələriniz və qərarverici qurumlarınızla birlikdə fəaliyyət göstərərək informasiya sistemləri ilə əlaqəli bütün avadanlıq, proqram və xidmətlərin biznesin ehtiyaclarını qarşılamasını və bir-birinə uyğun olmasını təmin edirik.</h2>
        </v-card-text>
      </v-card>
      <br/>
      <h2>
        IS Outsourcing paketi sizin ehtiyacınız olan informasiya sisteminə sahib olmanız üçün lazım olan bütün əsas xidmətləri əhatə edir.
      </h2><br/>
      <p style="vertical-align: middle;">
        <img class="icn" :src="require('/src/assets/icons/nav.png')"/>      
        <txt-emp>İnformasiya Sistemləri strategiyası</txt-emp></p>
      <p>
        Biznesinizin uzunmüddətli fundamental tələblərinə uyğun doğru informasiya sistemi strategiyası müasir iş mühitində xüsusi əhəmiyyətə sahibdir. Stratejik prioritetlərin, metodologiyaların və texnologiyaların təyini biznesin stratejik hədəflərinə çatması üçün çox önəmlidir. Doğru təyin edilmiş strategiyanın şirkət içində ardıcıl tətbiqi və ciddi nəzarəti sağlam və faydalı sistem sahibi olmanın tək yoludur.
      </p>
      <p style="vertical-align: middle;">
        <img class="icn" :src="require('/src/assets/icons/mng.png')"/>  
        <txt-emp>CIO</txt-emp></p>
      <p>
        Müasir dünyada istifadə etdiyi məlumatların sayı çox az və ya dəyəri çox aşağı olan şirkətlər xaricində hər bir biznesin direktor səviyyəli informasiya sistemi mütəxəssisinə ehtiyacı var. Büdcəsi buna uyğun olan bizneslər üçün ideal həll tam ştat yüksək kvalifikasiyalı IS direktoru (CIO) ilə çalışmaqdır. Amma ciddi "C-səviyyəli" mütəxəssis kiçik və orta biznes üçün həm çox baha başa gəlir, həm də şirkət belə bir işçiyə yetərli həcmdə dəyərinə uyğun iş verə bilmir. Bəzi şirkətlər informatika idarəetməsini daha ucuz və sadə texniki personala həvalə edir, amma biz bunun yerinə daha geniş görüşə sahib təcrübəli mütəxəssisin xidmətlərini sizə lazım olduğu qədər almağı məsləhət görürük. Beləliklə yüksək kvalifikasiyalı xidməti daha ucuza əldə edirsiniz və mütəxəssis də öz təcrübəsinə uyğun işlərlə məşğul olur.
      </p>
      <p style="vertical-align: middle;">
        <img class="icn" :src="require('/src/assets/icons/prc.png')"/>  
        <txt-emp>Proses analizi</txt-emp></p>
      <p>
        Proqram təminatının tətbiqindən əvvəl iş prosesinin hərtərəfli təhlili uğurlu nəticələr üçün çox vacibdir. Biznesin əsas hədəfləri dərindən analiz edilməli və proqram təminatının tətbiqinin bu biznes hədəflərinə uyğun olması təmin edilməlidir. Proqram təminatı üçün fikri faydalı ola biləcək işçilərin iştirakı ilə mövcud biznes proseslərin texniki analizinin aparılması həm proqram təminatının sağlam sxemlər əsasında yaradıla bilməsi üçün, həm də mövcud proseslərdə ola biləcək nöqsanların aşkarlanması və düzəldilməsi üçün əhəmiyyətlidir.
      </p>
      <p style="vertical-align: middle;">
        <img class="icn" :src="require('/src/assets/icons/inf.png')"/>  
        <txt-emp>İnfrastruktur</txt-emp></p>
      <p>
        Sağlam Informasiya Sistemi ancaq sağlam texniki infrastrukturda qurula bilər. İnfrastrukturun maksimum kəsintisiz, qorunan və bərpa edilə bilən olmasına xüsusi diqqət göstərilməlidir. Biz biznesinizin ehtiyaclarından asılı olaraq daxili (şirkət içi) və ya xarici (kənar tədarükçülərə aid) şəbəkə, server, verilənlər bazası və digər texniki infrastruktur elementlərinin seçilməsi, quraşdırılması və idarəedilməsini sizin adınızdan yerinə yetirə bilərik. 
      </p>
      <p style="vertical-align: middle;">
        <img class="icn" :src="require('/src/assets/icons/prg.png')"/>  
        <txt-emp>Proqram təminatı</txt-emp></p>
      <p>
        Proqram təminatı Informasiya Sisteminin ən vacib hissəsidir, və biznesin hədəfləri və detallı biznes proseslər əsasında doğru proqram təminatı seçmək və ya yaratmaq bizim əsas xidmət sahəmizdir. IS Outsourcing paketi çərçivəsində xidmət göstərilməsi halında biz sizin İnformasiya Sistemləri şöbəsi funksiyasını yerinə yetirərək sizin ehtiyacınız olan proqram təminatına sahib olmanızı təmin edirik. Mümkün olduğu hallarda sizə uyğun proqram təminatı seçərək onu tətbiq edir, ehtiyac olduqda sizin proseslərə uyğun proqram təminatı yaradırıq.  
      </p>
      <p style="vertical-align: middle;">
        <img class="icn" :src="require('/src/assets/icons/mnt.png')"/>  
        <txt-emp>Texniki dəstək</txt-emp></p>
      <p>
        Outsourcing paketi İnformasiya Sistemini formalaşdıran bütün texniki elementlərin texniki dəstəyini əhatə edir. Bu dəstəyin əsas məqsədi serverlər, şəbəkələr, proqram təminatı, əlavə avadanlıq və sistemlərin maksimum kəsintisiz işlək vəziyyətdə olmasını, kritik sistemlərin bərpa edilə bilən və əvəz oluna bilən olmasını, və kiber təhlükələrə qarşı qorunmuş olmasını təmin etməkdir.
      </p>
      <p style="vertical-align: middle;">
        <img class="icn" :src="require('/src/assets/icons/hlp.png')"/>  
        <txt-emp>İstifadəçi dəstəyi</txt-emp></p>
      <p>
        Kompüter avadanlığı və proqram təminatı ilə işləyən istifadəçilərin dəstəyi də outsourcing çərçivəsində yerinə yetirilir. Texniki vasitələrlə işləmək üçün lazım olan manualların təmin edilməsi, istifadəçilərə öz işlərini görə bilmələri üçün gərəkli yerində və uzaqdan canlı dəstək İnformasiya Sisteminin məlumatlarının operativ və doğru olmasına kömək edir. Dəstək çərçivəsində istifadəçi təlimləri verilir və attestasiya keçirilir.
      </p>
      </v-container>
      <contact-text />
</div>    
  
</template>

<script>
import OpenInNew from "@/ui/atoms/OpenInNew.vue";
import RU from "@/ui/atoms/Ru.vue";
import ContactText from "@/ui/atoms/ContactText.vue";

export default {
  components: { OpenInNew, RU, ContactText },

};
</script>

<style lang="scss" scoped>
@import "/src/styles/styles.scss";
._sub::before {
  display: block;
  content: " ";
  margin-top: -120px;
  height: 120px;
  visibility: hidden;
  pointer-events: none;
}

</style>


