<template>
  <div class="blk">
    <contact-text
      text1="Bizimlə "
      text2=" saxlayın"
      text3="sizin üçün də faydalı olaq"
    ></contact-text>
    <br />
  </div>
</template>

<script>
import ContactText from "@/ui/atoms/ContactText.vue";

export default {
  components: { ContactText },
};
</script>

<style lang="scss" scoped>
@import "@/styles/styles.scss";
</style>
