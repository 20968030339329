import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VParallax } from 'vuetify/lib/components/VParallax';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ts-tmp"},[_c(VContainer,{staticClass:"ts-con inf"},[_c('span',[_c(VRow,[_c(VCol,[_c(VChip,{staticClass:"num",attrs:{"x-large":"","label":"","outlined":""}},[_c('span',{attrs:{"id":"y"}},[_vm._v("0")])]),_c('div',{staticClass:"xs"}),_c(VChip,{attrs:{"x-large":"","color":"white"}},[_c('span',{staticClass:"lbl"},[_vm._v("il")])])],1),_c(VCol,[_c(VChip,{staticClass:"num",attrs:{"x-large":"","label":"","outlined":""}},[_c('span',{attrs:{"id":"m"}},[_vm._v("0")])]),_c('div',{staticClass:"xs"}),_c(VChip,{attrs:{"x-large":"","color":"white"}},[_c('span',{staticClass:"lbl"},[_vm._v("ay")])])],1),_c(VCol,[_c(VChip,{staticClass:"num",attrs:{"x-large":"","label":"","outlined":""}},[_c('span',{attrs:{"id":"d"}},[_vm._v("0")])]),_c('div',{staticClass:"xs"}),_c(VChip,{attrs:{"x-large":"","color":"white"}},[_c('span',{staticClass:"lbl"},[_vm._v("gün")])])],1)],1)],1),_c('br'),_c(VCard,[_c(VParallax,{attrs:{"alt":"-","height":"300","src":require('/src/assets/bg/whbl.jpg'),"aspect-ratio":"3"}},[_c('div',{attrs:{"id":"time-txt"}},[_vm._v(" biznes üçün informasiya sistemlərinin planlanması, yaradılması və dəstəyi ilə xidmətinizdəyik ")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }