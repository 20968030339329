import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_vm._m(0),_vm._l((_setup.prods),function(prod){return _c(VRow,{key:prod.id},[_c(VCol,[_c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"justify-center"},[_c(VImg,{staticClass:"dm-prd-img",attrs:{"src":require(`/src/pages/Products/DataMobile/${prod.imgUri}`)}})],1),_c(VCardActions,{staticClass:"justify-center"},[_c('h3',[_vm._v(_vm._s(prod.title))])])],1),_c('br'),(prod.comp=='DM')?_c(_setup.DM):_vm._e(),(prod.comp=='FA')?_c(_setup.FA):_vm._e(),(prod.comp=='PC')?_c(_setup.PC):_vm._e(),(prod.comp=='DD')?_c(_setup.DD):_vm._e(),(prod.comp=='MT')?_c(_setup.MT):_vm._e()],1)],1)})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"mx-6"},[_vm._v(" Scanport şirkətinin DataMobile mobil proqram həlləri anbar, satış, istehsalat və logistika sahələrinin avtomatlaşdırılması üçün nəzərdə tutulub. Proqram həlləri Android əməliyyat sistemli mobil terminallarda barkod və RFID vasitəsi ilə mal və inventarın qalıq və hərəkətlərinin qeydiyyatını aparmaq imkanı verir. Proqramların əsas funksionalı aşağıdakılardan ibarətdir: "),_c('ul',[_c('li',[_vm._v("malların identifikasiyası")]),_c('li',[_vm._v("malların qəbulu, verilməsi və yerdəyişməsi")]),_c('li',[_vm._v("sayım")]),_c('li',[_vm._v("sifarişlərin yığılması")]),_c('li',[_vm._v("rəflər üzrə qalıq")]),_c('li',[_vm._v("qiymət yoxlama")]),_c('li',[_vm._v("qiymət dəyişdirmə")])])])
}]

export { render, staticRenderFns }