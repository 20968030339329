<template>
  <v-sheet class="tm">
    <v-row class="pt-0 mt-0">
      <v-btn-toggle tile class="tmt" mandatory
        ><span class="tmb">
          <v-btn
            plain
            :class="
              item.id == selectedItem ? 'smi' : item.id == 0 ? 'mil' : 'mir'
            "
            v-for="item in menu"
            :key="item.id"
            :to="item.path"
            >{{ item.title[lang] }}
          </v-btn></span
        >
      </v-btn-toggle>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  data: () => ({}),
  computed: {
    menu() {
      return this.$store.state.menu;
    },
    lang() {
      return this.$store.state.lang;
    },
    menuItemTitle(i) {
      return this.$store.state.menu[i].title[lang()];
    },
    selectedItem() {
      return this.$store.state.selectedItem;
    },
  },
  methods: {},
};
</script>

<style lang="scss">
@import "@/styles/styles.scss";

.smi {
  background-color: $red !important;
  color: white !important;
  top: 0;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.mil {
  border: none !important;
  border-left: lightgray solid 1px !important;
  border-right: lightgray solid 1px !important;
}
.mir {
  border: none !important;
  border-right: lightgray solid 1px !important;
}
.tmt {
  position: absolute !important;
  top: 0px !important;
  right: 0px !important;
}
.tmb {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    display: block;
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    display: none;
  }
}

.tm {
  width: 600px !important;
}
</style>
