import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exp-tmp"},[_c(VContainer,{staticClass:"exp-con inf"},[_c(VRow,{attrs:{"align-content":"center"}},[_c(VCol,[_c(VChip,{staticClass:"num",attrs:{"x-large":"","label":"","outlined":""}},[_c('span',[_vm._v(_vm._s(_vm.no_ind))])])],1)],1),_c('div',{staticClass:"lbl"},[_vm._v("sektorda")]),_c('div',[_vm._v("avtomatlaşdırma təcrübəmiz var")]),_c('br')],1),_c(VContainer,{staticClass:"ind-crd-con"},[_c(VRow,_vm._l((_vm.indCards),function(item){return _c(VCol,{key:item.id,attrs:{"cols":"12","sm":"6"}},[_c(VCard,{staticClass:"pa-2 ind-crd",attrs:{"elevation":"10"}},[_c(VList,[_c(VListItem,[_c(VIcon,{staticClass:"ind-icn"},[_vm._v(_vm._s(item.icon))]),_c(VListItemTitle,{staticClass:"ind-txt",domProps:{"innerHTML":_vm._s(item.name)}})],1)],1)],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }