<template>
  <v-container class="cnt-txt">
    <v-row
      ><v-col
        >{{ text1 || "Daha ətraflı məlumat üçün bizimlə " }} <contact-button />
        {{ text2 || " saxlayın" }}
      </v-col></v-row
    ><v-row
      ><v-col>
        <div>{{ text3 }}</div></v-col
      ></v-row
    >
    <br />
  </v-container>
</template>

<script>
import ContactButton from "@/ui/atoms/ContactButton.vue";

export default {
  components: { ContactButton },
  props: ["text1", "text2", "text3"],
};
</script>

<style lang="scss" scoped>
@import "@/styles/styles.scss";
.cnt-txt {
  padding-top: 50px;
  padding-left: 0;
  font-size: x-large;
  text-align: center;
  font-weight: 800;
  background-color: whiitesmoke;
  max-width: 600px;
}
.act-btn {
  background-color: $red !important;
  border-radius: 50px;
  top: -2px;
}
.act-btn a {
  color: white !important;
  font-size: x-large !important;
  text-decoration: none !important;
}
</style>
