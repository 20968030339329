<template>
  <div class="exp-tmp">
    <v-container class="exp-con inf">
      <v-row align-content="center">
        <v-col>
          <v-chip class="num" x-large label outlined>
            <span>{{ no_ind }}</span></v-chip
          >
        </v-col></v-row
      >
      <div class="lbl">sektorda</div>
      <div>avtomatlaşdırma təcrübəmiz var</div>
      <br />
    </v-container>
    <v-container class="ind-crd-con">
      <v-row>
        <v-col cols="12" sm="6" v-for="item in indCards" :key="item.id">
          <v-card class="pa-2 ind-crd" elevation="10">
            <v-list
              ><v-list-item 
                ><!--v-btn fab 
                --><v-icon class="ind-icn">{{ item.icon }}</v-icon><!--/v-btn
                -->
                <v-list-item-title
                  class="ind-txt"
                  v-html="item.name"
                ></v-list-item-title></v-list-item></v-list
          ></v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      indCards: [
        { id: 1, name: "Kimya sənayesi", icon: "mdi-flask" },
        { is: 2, name: "Ağır texnika istehsalı", icon: "mdi-excavator " },
        { id: 3, name: "Qida kimyası", icon: "mdi-cookie-edit-outline" },
        {
          id: 4,
          name: "Elektrik avadanlığı istehsalı",
          icon: "mdi-light-switch",
        },
        { id: 5, name: "Aqrar sənaye", icon: "mdi-food-apple-outline" },
        { id: 6, name: "Tikinti", icon: "mdi-domain" },
        { id: 7, name: "Tekstil istehsalı", icon: "mdi-curtains" },
        { id: 8, name: "Avtoservis", icon: "mdi-car-wrench" },
        { id: 9, name: "Avtomobil satışı", icon: "mdi-car-select" },
        { id: 10, name: "Ev əşyaları satışı", icon: "mdi-cupboard-outline" },
        { id: 11, name: "Elektronika mağazası", icon: "mdi-washing-machine" },
        { id: 12, name: "Paltar mağazası", icon: "mdi-tshirt-crew-outline" },
        { id: 13, name: "Supermarket", icon: "mdi-shopping-outline" },
        { id: 14, name: "İnternet xidmətləri", icon: "mdi-web" },
        { id: 15, name: "Logistika", icon: "mdi-truck-cargo-container" },
        { id: 16, name: "Restoran", icon: "mdi-silverware" },
      ],
    };
  },
  computed: {
    no_ind() {
      return this.indCards.length;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/styles.scss";

.exp-tmp {
  background-color: whitesmoke;
  padding-bottom: 50px;
}

.exp-con {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 50px;
  max-width: 600px;
}

.ind-crd-con {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    max-width: 600px;
  }  
}

.ind-icn {
  font-size: xx-large;
  color: $red;
}

.ind-icn:hover {
  transform: scale(1.5);
}

.ind-txt {
  font-size: large;
  color: $blue;
  margin-left: 10px;
  white-space: normal;
}


</style>
