<template>
  <v-card>
    <v-app-bar app height="90" class="rt wb">
      <hamburger-menu></hamburger-menu>
      <a href="/">
        <v-img
          alt="egosoft"
          contain
          eager
          max-height="48"
          max-width="200"
          src="@/assets/egosoft.png"
          rel="preload"
        >
        </v-img
      ></a>
      <whats-app class="wa" />
      <phone-num class="ph" />
      <!--whats-app-phone class="wa-com" /--><v-spacer />
      <top-menu></top-menu>
    </v-app-bar>

    ><!--v-sheet> height="30px" width="100%" color="white" ></v-sheet-->
  </v-card>
</template>

<script>
import TopMenu from "@/ui/components/TopMenu";
import HamburgerMenu from "@/ui/components/HamburgerMenu.vue";
import WhatsApp from "@/ui/atoms/WhatsApp.vue";
import PhoneNum from "@/ui/atoms/PhoneNum.vue";

export default {
  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint.name;
    },
    selectedItem() {
      return this.$store.state.selectedItem;
    },
  },
  components: {
    TopMenu,
    HamburgerMenu,
    WhatsApp,
    PhoneNum,
  },
  data: () => {
    return {
      //drawer: null,
      //hamburgerMenu: null,
    };
  },
  methods: {
    toggleHamburgerMenu() {
      this.$store.dispatch("toggleHM");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/styles.scss";
.rt {
  border-top-color: $red !important;
  border-top-style: solid;
  border-top-width: 10px;
  border-radius: 0 !important;
}
.wb {
  background-color: white !important;
}

.wa {
  position: absolute;
  display: table;
  vertical-align: middle;
  display: table-cell;
  z-index: 2;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    top: 40px;
    right: 10px;
  }
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    top: 10px;
    left: 300px;
  }

  @media #{map-get($display-breakpoints, 'md-only')} {
    top: 40px;
    left: 300px;
  }
}

.ph {
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    position: relative;
    display: inline;
    top: 10px;
    left: 100px;
    min-width: 600px;
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    display: none;
  }

  @media #{map-get($display-breakpoints, 'md-only')} {
    display: none;
  }
}
</style>
