import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"srv-crd-con"},[_c(VRow,{attrs:{"align-content":"center"}},_vm._l((_vm.srvCards),function(item){return _c(VCol,{key:item.id,staticClass:"d-flex flex-column srv-crd",attrs:{"cols":"12","sm":"6","md":"6","lg":"6","xl":"6"}},[_c(VCard,{staticClass:"mx-auto flex",attrs:{"elevation":"5","width":"100%","height":"100%","min-width":"200px","max-width":"600px"}},[_c('a',{staticClass:"txt-dec-non",attrs:{"href":item.link}},[_c(VCardTitle,{staticClass:"srv-crd-ttl pb-5 justify-center"},[_c(VBtn,{staticClass:"srv-crd-icn-btn",attrs:{"fab":""}},[_c(VIcon,{staticClass:"srv-crd-icn",attrs:{"x-large":""}},[_vm._v(_vm._s(item.icon)+" ")])],1)],1),_c(VCardTitle,{staticClass:"srv-crd-ttl pb-5 justify-center"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c(VCardText,{staticClass:"pt-2 srv-crd-txt pt-5"},[_vm._v(" "+_vm._s(item.text))])],1)])],1)}),1),_c('br'),_c('contact-text')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }