<template>
  <v-app class="pl-0 pr-0" id="app">
    <site-header></site-header>
    <page-header v-if="showPageHeader"></page-header>
    <v-main class="mt-0 pl-0 pr-0 not-sel-text">
      <div v-if="showPageHeader" class="top-sep">&nbsp;</div>
      <router-view class="pl-0 pr-0" />
      <nav-btn />
      <page-footer />
    </v-main>
  </v-app>
</template>

<script>

import router from "@/router/index.js";
import store from "@/store/index.js";

import SiteHeader from "@/ui/components/SiteHeader";
import PageHeader from "@/pages/PageHeader";
import NavBtn from "@/ui/components/NavBtn";
import PageFooter from "@/pages/PageFooter";


export default {
  name: "App",
  metaInfo () {
    return {
      title: "egosoft.az",
      titleTemplate: "egosoft.az | %s",
      htmlAttrs: {
        amp: true,
        lang: "az",
      },
      link: [{rel: "canonical", href: "https://egosoft.az"+this.canonicalLink}]
    }
  },
  components: {
    SiteHeader,
    PageHeader,
    NavBtn,
    PageFooter,
  },
  data() {
    return {  
    };
  },
  methods: {
    CountUp(id, from, to, delay) {
      from++;
      if (from <= to) {
        document.getElementById(id).innerHTML = from;
        setTimeout(this.CountUp, delay, id, from, to, delay);
      }
    },
  },
  computed: {
    showPageHeader() {
      let id = 0;
      try {
        id = this.$store.state.menu[this.$store.state.selectedItem].id;
      } catch (e) {}
      return id != 0;
    },
    canonicalLink () {
      return this.$route.fullPath;
    }
  },
  mounted() {
    document.title = "egosoft.az";

    addGA();
  }
};

function addGA() {
  let GAScript = document.createElement("script");
  GAScript.setAttribute("src", "GA.js");
  document.head.appendChild(GAScript);

  let GAnoscript = document.createElement("noscript");
  let iframe = document.createElement("iframe");
  iframe.setAttribute(
    "src",
    "https://www.googletagmanager.com/ns.html?id=GTM-MNF8TSF"
  );
  iframe.setAttribute("height", "0");
  iframe.setAttribute("width", "0");
  iframe.setAttribute("style", "display:none;visibility:hidden");
  GAnoscript.appendChild(iframe);

  document.body.insertBefore(GAnoscript, document.body.firstChild);
  document.body.appendChild(GAnoscript);
}

</script>

<style lang="scss">
@import "@/styles/styles.scss";

body{
  background-color: whitesmoke;   
}

#app{
  max-width: 1280px;
  margin: auto;
  background-color: white;
  box-shadow: gray 0px 0 30px 6px;
}
.btn {
  text-transform: none !important;
}

.top-sep {
  height: 56px;
}

.shw-snc {
  position: absolute;
  top: 150px;
  z-index: 10;
}

</style>
