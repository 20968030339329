<template>
  <div class="pt-0 mt-0">
    <div class="con-tmp">
      <v-snackbar
        timeout="4000"
        v-model="snackbar.show"
        centered
        app
        top
        multi-line
        elevation="10"
        :color="snackbar.color"
      >
        <v-row
          ><v-col> {{ snackbar.text }}</v-col
          ><v-col style="text-align: right">
            <v-icon style="text-align: right" @click="showSnackbar(false)"
              >mdi-close</v-icon
            ></v-col
          ></v-row
        >
      </v-snackbar>

      <v-container class="con-cnt inf">
        <v-card>
          <v-card-title class="con-hdr">WhatsApp və telefon</v-card-title>
          <v-row><v-col></v-col> </v-row>
          <v-row
            ><v-col> <phone-num /> </v-col
          ></v-row>
          <v-row
            ><v-col>
              <whats-app style="display: inline-block" />&nbsp;&nbsp;
              <phone-icon style="display: inline-block" /></v-col
          ></v-row> </v-card
        ><br /><br /><v-card>
          <v-card-title class="con-hdr">E-poçt</v-card-title>
          <v-row><v-col></v-col> </v-row>
          <v-row
            ><v-col> <e-mail-adr /> </v-col></v-row
          ><v-row
            ><v-col> <e-mail-icon /> </v-col></v-row></v-card
        ><br /><br /><v-card>
          <v-card-title class="con-hdr">Mesaj forması</v-card-title>
          <v-form class="pa-10" ref="form" v-model="webform.valid">
            <v-text-field
              v-model="webform.sender"
              label="Ad soyad"
              validate-on-blur
              required
              :rules="nameRules"
            ></v-text-field>

            <v-text-field
              v-model="webform.email"
              :rules="emailRules"
              validate-on-blur
              placeholder="ad@domain.az"
              label="E-poçt"
            ></v-text-field>

            <v-text-field
              v-model="webform.tel"
              :rules="telRules"
              validate-on-blur
              placeholder="+994(00)123-4567"
              label="Telefon"
            ></v-text-field>

            <!--             <v-select
              v-model="select"
              :items="items"
              :rules="[(v) => !!v || 'Mövzu seçin']"
              label="Mövzu"
              required
            ></v-select>
 -->
            <v-textarea
              v-model="webform.text"
              label="Mətn"
              :rules="[
                (v) => (v && v.length >= 10) || 'Ən az 10 simvol daxil edin',
              ]"
            ></v-textarea>

            <v-btn
              :disabled="!webform.valid"
              color="success"
              class="mr-4"
              @click="sendMail"
            >
              Göndər
            </v-btn>
          </v-form></v-card
        >
      </v-container>
    </div>
  </div>
</template>

<script>
import WhatsApp from "@/ui/atoms/WhatsApp.vue";
import PhoneIcon from "@/ui/atoms/PhoneIcon.vue";
import PhoneNum from "@/ui/atoms/PhoneNum.vue";
import EMailIcon from "@/ui/atoms/EMailIcon.vue";
import EMailAdr from "@/ui/atoms/EMailAdr.vue";

import axios from "axios";

export default {
  name: "Contact",
  data() {
    return {
      snackbar: { show: false, text: "????", color: "red" },
      webform: {},
      emailRules: [
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) ||
          !v ||
          "E-poçt ünvanı doğru deyil",
      ],
      telRules: [
        (v) =>
          /^((([\+][0-9]{1,3})|(0))?[(]?[0-9]{1,3}[)]?)[1-9]([\.\s\-]?\d){6}$/.test(
            v
          ) ||
          !v ||
          "Telefon nömrəsi doğru deyil",
      ],
      nameRules: [
        //(v) => !v || "Ən az 3 simvol daxil edin 1",
        (v) => (v && v.length >= 3) || "Ən az 3 simvol daxil edin",
      ],
    };
  },
  metaInfo: {
    title: "əlaqə",

    meta: [
      {
        name: "description",
        content:
          "Bizimlə əlaqə saxlayın, biznesiniz üçün doğru İnformasiya Sistemi qurmağınıza kömək edək",
      },
    ],
  },

  components: { WhatsApp, PhoneNum, PhoneIcon, EMailIcon, EMailAdr },

  methods: {
    sendMail: function () {
      const form = new FormData();
      form.append(
        "from",
        this.webform.sender +
          "[" +
          this.webform.tel +
          "]" +
          "<" +
          this.webform.email +
          ">"
      );
      form.append("subject", "Web mesaj: " + this.webform.sender);
      form.append("text", this.webform.text);
      var snackbarData = { show: false, text: "test", color: "white" };
      axios
        .post("https://egosoft.az/sm.php", form, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(function (response) {
          if (response.data.status == 200) {
            snackbarData.show = true;
            snackbarData.text = "Mesaj göndərildi";
            snackbarData.color = "green";
          } else {
            snackbarData.show = true;
            snackbarData.text =
              "Mesaj göndərilmədi! Xəta: " + response.data.message;
            snackbarData.color = "yellow";
          }
        })
        .catch(function (response) {
          snackbarData.show = true;
          snackbarData.text = "Mesaj göndərilmədi! Xəta: " + response.message;
          snackbarData.color = "red";
        })
        .finally(() => {
          this.showSnackbar(snackbarData);
          this.initForm();
        });
    },
    showSnackbar(snackbarData) {
      this.snackbar.show = snackbarData.show;
      this.snackbar.color = snackbarData.color;
      this.snackbar.text = snackbarData.text;
    },
    initForm() {
      this.webform.valid = false;
      this.webform.sender = "";
      this.webform.email = "";
      this.webform.tel = "";
      this.webform.text = "";
    },
  },

  mounted() {
    this.initForm();
  },

  body: function () {
    this.body +
      "\n\n<hr>" +
      "Ad:\t" +
      this.form.sender +
      "\nE-poçt:\t" +
      this.form.email +
      "\nTel:\t" +
      this.form.tel;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/styles.scss";

.con-cnt {
  padding-top: 50px;
}

.con-tmp {
  padding-bottom: 50px;
}

.con-hdr {
  color: $red;
  //background-color: whitesmoke;
  font-size: x-large;
  text-shadow: 2px 2px lightgray;
  font-weight: 600;
  border-bottom: 2px $red solid;
  display: inline-block;
}

.v-list-item--active {
  background-color: aqua !important;
}
</style>