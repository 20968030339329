<script setup>
  import PageTop from "@/pages/Products/PageTop.vue";
  import NarrowP from "@/ui/atoms/NarrowP.vue";
  import ContactText from "@/ui/atoms/ContactText.vue";
  import DataMobileProducts from "./DataMobileProducts.vue";
  
  const fn = [
  {
    // st / pr / li / on
    name: "Malların girişi, çıxışı və yerdəyişməsi",
    set: [true, true, true, true],
  },
  {
    name: "Sayım",
    set: [true, true, true, true]
  },
  {
    name: "Sənəd şablonları",
    set: [true,
      true,
      true,
      true]
  },
  {
    name: "İstifadəçi girişi",
    set: [true,
      true,
      true,
      true]
  },
  {
    name: "Terminalda şablon dəyişdirmə",
    set: [true,
      true,
      true,
      true]
  },
  {
    name: "Terminalların fiziki düymələrinin dəstəklənməsi",
    set: [true,
      true,
      true,
      true]
  },
  {
    name: "Əməliyyatlar üçün səs siqnalları",
    set: [true,
      true,
      true,
      true]
  },
  {
    name: "Malların rəf ünvanları",
    set: [false,
      true,
      true,
      true]
  },
  {
    name: "Çəki listələri",
    set: [false,
      true,
      true,
      true]
  },
  {
    name: "Tapşırıq üzrə mal yığımı",
    set: [false,
      true,
      true,
      true]
  },
  {
    name: "Birdən çox barkod istifadəsi",
    set: [true,
      true,
      true,
      true]
  },
  {
    name: "Seriya nömrələri",
    set: [false,
      true,
      true,
      true]
  },
  {
    name: "Yeni malın terminaldan tanıdılması",
    set: [true,
      true,
      true,
      true]
  },
  {
    name: "Mobil çap",
    set: [true,
      true,
      true,
      true]
  },
  {
    name: "Online rejim",
    set: [false,
      false,
      true,
      true]
  },
  {
    name: "Online /offline rejim",
    set: [false,
      false,
      true,
      true]
  },
  {
    name: "Online çap",
    set: [false,
      false,
      false,
      true]
  },
  {
    name: "Sürətli keçid",
    set: [false,
      false,
      false,
      true]
  },
  {
    name: "Sənədlərlə online qrup olaraq işləmə",
    set: [false,
      false,
      false,
      true]
  },
  {
    name: "Sətirin serverə online göndərilməsi",
    set: [false,
      false,
      false,
      true]
  },
  {
    name: "Əsas bazadakı dəyişikliyin terminalda görünməsi",
    set: [false,
      false,
      false,
      true]
  },
  {
    name: "Əsas bazadakı şəklin terminalda görünməsi",
    set: [false,
      false,
      false,
      true]
  },
  {
    name: "Terminalda yeni barkod yaratma",
    set: [false,
      false,
      false,
      true]
  },
  {
    name: "Obyektlərin online foto qeydiyyatı",
    set: [false,
      false,
      false,
      true],
  },
  ];


</script>

<template>
  <div>    
    <page-top 
      title="Scanport DataMobile"
      imgUri = 'DataMobile/DataMobile.png'
      imgAlt = "Scanport DataMobile">
    </page-top>

    <narrow-p>
      <!--p class="hdr">
            DataMobile Android əl terminalları üçün Scanport firmasının universal proqram həllidir
      </p-->
      <br>
      <data-mobile-products/>
      <br>
      <!--h1>DEL..</h1>
      <d-m/>
      <p>
        Proqram anbar və satış nöqtələrində istifadə üçün geniş funksional
        imkanlara sahibdir. İstənilən biznes prosesin parametrik köklənməsi
        sayəsində məhsul ən müxtəlif sahələrdə asanlıqla istifadə edilə bilər.
        Proqramın bir neçə versiyası mövcuddur, ehtiyacınıza uyğun versiya seçə
        bilər, lazım gələrsə sonra daha yüksək versiyaya keçə bilərsiniz.
      </p>
      <p>
        DataMobile proqramı bütün yayılmış əl terminalı modellərini dəstəkləyir.
        Proqram səhvlərinin aradan qaldırılması və yeni funksionala sahib olmaq
        üçün pulsuz yeniləmələrdən istifadə edə bilərsiniz.
      </p>
      <p class="center hdr"> DataMobile-ın funksionalı</p>
      <p max-width="600">
        <ul>
          <li>Mal və əsas vəsaitlərin sayımı</li>
          <li>Malların girişi, çıxışı və yerdəyişməsi</li>
          <li>Seriya nömrələri üzrə uçot</li>
          <li>Qablaşdırma funksionalı</li>
          <li>Malların identifikasiyası</li>
          <li>Nümunələr üzrə satış</li>
          <li>Sifarişin yığılması</li>
          <li>Mobil yenidən dəyərləndirmə</li>
          <li>Şəbəkə printerləri ilə etiket və sənədlərin çapı</li>
          <li>Sıraların aradan qaldırılması</li>
          <li>Rəflər üzrə yerləşmə</li>
          <li>İstifadəçi avtorizasiyası</li>
          <li>Terminalda şablonların işlənməsi</li>
          <li>Terminal düymələrindən istifadə</li>
          <li>Əməliyyat və mesajlar üçün səsli bildiriş</li>
        </ul>  
      </p>
      <p class="hdr">DataMobile-ın əsas üstünlükləri</p>
          <p>
            <ul>
          <li>
            Biznes proseslərin proqramlaşdırmaya ehtiyac olmadan, sadəcə
            parametrlərlə köklənməsi
          </li>
          <li>Tam on-line rejim və dinamik rəf adresləri</li>
          <li>Birbaşa 1С-lə işləmə imkanı</li>
          <li>Geniş çeşiddə Android mobil qurğularının dəstəyi</li>
          <li>Periodik pulsuz yeniləmə və yeni funksional</li>
          <li>Pulsuz online dəstək</li>
          <li>
            Kompüterlə MS ActiveSync, FTP, Wi-Fi və 3G/LTE ilə məlumat
            mübadiləsi
          </li>
        </ul></p>
        <p class="hdr">
          DataMobile versiyalarının dəstəklədiyi funksional
          </p>
        <v-row v-for="item in fn" :key="item.name">
        <v-col>
          <v-row>
            <v-col cols="12" sm="12" md="4">
              {{ item.name }}
            </v-col>
            <v-col v-for="i in 4" cols="3" sm="3" md="2" class="tbl">
              <v-icon color="green" v-if="item.set[i-1]">mdi-check-bold</v-icon>
              <v-icon color="red" v-if="!item.set[i-1]">mdi-minus</v-icon>
            </v-col>
          </v-row
        ></v-col
      ></v-row>
    <h1>..DEL</h1-->
          <contact-text
      text1="DataMobile əsasında mobil həllər üçün bizimlə"
      text2=" saxlamanızı xahiş edirik."
    />
    
    
    </narrow-p>



  </div>
</template>

<style lang="scss" scoped>
@import "/src/styles/styles.scss";
a {
  text-decoration: none !important;
}

.hdr {
  font-size: large;
  font-weight: 800;
  word-break: keep-all;
  text-align: center;
}

.tbl {
  border: 1px solid whitesmoke;
  text-align: center;
}

.txt90 {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    text-align: center;
  }
}

.img {
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    width: 500px;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    width: 300px;
  }
}
.crd-img {
  width: 100%;
  max-width: 500px;
}
</style>