<template>
  <v-container class="ph not-sel-text">
    <v-row class="ma-0" no-gutters>
      <v-col cols="2" sm="4" class="pa-0">
        <router-link v-if="prevPage.path" class="ntd pn" :to="prevPage.path">
          <v-btn class="cr" fab small>
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <span class="ma-2 nav-text">{{ prevPage.title }}</span>
        </router-link>
      </v-col>
      <v-col cols="8" sm="4" class="pa-0"
        ><router-link class="no-u" v-if="pageLink" :to="pageLink">
          <v-sheet class="pt-2 pb-2 pl-5 pr-5 pt">{{
            pageTitle
          }}</v-sheet></router-link
        ></v-col
      >
      <v-col cols="2" sm="4" class="text-right pa-0">
        <router-link v-if="nextPage.path" class="ntd pn" :to="nextPage.path">
          <span class="ma-2 nav-text">{{ nextPage.title }}</span>
          <v-btn class="cr" fab small>
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  computed: {
    prevPage() {
      let res = { path: "" };
      let i = this.$store.state.selectedItem;
      if (i == null) {
        i = 0;
      }
      if (i > 0) {
        res.title = this.$store.state.menu[i - 1].title[this.$store.state.lang];
        res.path = this.$store.state.menu[i - 1].path;
      }
      return res;
    },
    nextPage() {
      let res = {};
      let i = this.$store.state.selectedItem;
      if (i < this.$store.state.menu.length - 1) {
        res.title = this.$store.state.menu[i + 1].title[this.$store.state.lang];
        res.path = this.$store.state.menu[i + 1].path;
      }
      return res;
    },

    pageTitle() {
      return this.$store.state.menu[this.$store.state.selectedItem].title[
        this.$store.state.lang
      ];
    },
    pageLink() {
      return this.$store.state.menu[this.$store.state.selectedItem].path;
    },
    selectedItem() {
      return this.$store.state.selectedItem;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/styles.scss";
.ph {
  position: fixed;
  padding-top: 24px;
  top: 80px;
  z-index: 2;
  background-color: white;
  height: 80px;
  max-width: 1280px;
  opacity: 100%;
  border-bottom: lightgray 1px solid;
}
.ph-sp {
  height: 40px;
  background-color: yellow;
}

.pt {
  font-weight: 800;
  font-size: large;
  text-align: center;
  color: $red !important;
  text-shadow: lightgray 2px 2px;
  background-color: white;  
}

.pn {
  text-shadow: lightgray 1px 1px;
}
.hash {
  color: $red;
  text-shadow: none;
}
/* 	.hash:hover {
			color: $red;
		} */
.ntd {
  text-decoration: none;
  color: $blue !important;
}
.nav-text {
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    display: true;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    display: none;
  }
}

.no-u{
  text-decoration: none;
}
</style>
