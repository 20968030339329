<template>
  <v-container>
    <div class="c404">404</div>
    <div class="ref">{{ ref }}</div>
    <div class="pnf">
      <span>səhifə tapılmadı </span><span style="color: #930606">:</span>(
    </div>
    <v-container class="go-con">
    <a href="/" >    
        <v-btn class="go-btn"><v-icon class="go-icn">mdi-home</v-icon></v-btn>
        &nbsp;
        <span class="go-txt">əsas səhifəyə</span>
    </a>    </v-container>
    <v-container class="go-con" >    
        <v-btn class="go-btn" onclick="history.go(-1)"><v-icon class="" style="text-align: center; font-size: xx-large">mdi-arrow-left</v-icon></v-btn>
        &nbsp;
        <a class="go-txt" onclick="history.go(-1)">geri</a>
      </v-container
    >
    <page-space />
  </v-container>
</template>

<script>
import PageSpace from "@/ui/atoms/PageSpace.vue";

export default {
  name: "PageNotFound",
  metaInfo: {
    title: "404",
    meta: [
      {
        name: "description",
        content: "Səhifə tapılmadı",
      },
    ],
  },
  data() {
    return {
      ref: window.location,
    };
  },
  components: { PageSpace },
};

function gohome() {
  window.location.replace("/");
}
</script>

<style lang="scss" scoped>
@import "@/styles/styles.scss";
.c404 {
  font-size: 128px;
  text-align: center;
  color: $red;
  font-weight: 1000;
  text-shadow: 2px 2px 10px gray;
}
.pnf {
  font-size: 72px;
  text-align: center;
  color: $blue;
  font-weight: 800;
  text-shadow: 1px 1px 5px gray;
}

a {
  text-decoration: none !important;
}
.ref {
  font-size: 24px;
  text-align: center !important;
  color: gray;
  font-weight: 400;
}

.go-con {
  padding-top: 50px;
  text-align: center !important;
  font-size: xx-large;
}
.go-btn {
  padding: 30px !important;
  font-size: 24px;
  text-align: center !important;
  color: green;
  font-weight: 600;
  text-shadow: 1px 1px 5px gray;
}

.go-icn{
  font-size: xx-large;
}
.go-txt{
  font-size: x-large;
}
</style>