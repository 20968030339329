<template>
  <div class="sub">
    <v-container class="txt-blc-con">
      <p class="txt-blc-hdr">1C:Müəssisə. Tipik həllər</p>
      <div style="display: flex; justify-content: center">
        <v-card elevation="10">
          <v-img
            alt="1C firmasının tipik həlləri"
            :src="require('/src/pages/Products/1C/1C.png')"
            class="pag-img"
          ></v-img
        ></v-card>
      </div>
      <br />
      <ul class="pag-ul">
        <p>
          1C:Müəssisə müasir tələblərə cavab verən biznes avtomatlaşdırma
          həllərinin yazılması üçün nəzərdə tutulmuş texnolojik platformadır.
          Platformanın yaradıcısı 1C şirkəti, bu platformada işləyən tipik
          həllərin də müəllifidir. 1C platformasındakı həllərə konfiqurasiya
          deyilir və ən çox yayılmış tipik konfiqurasiyaların əsas özəllikləri
          aşağıdadır:
        </p>
        <li>
          <a href="https://v8.1c.ru/buhv8/" target="_blank">1C:Mühasibat</a
          >&nbsp;<RU /><OpenInNew />
        </li>

        <p>
          Bu konfiqurasiya 1C-in ən çox bilinən həllidir. Mühasibat
          konfiqurasiyası əsas əməliyyatları əks etdirən sənədlərin
          doldurulması, lazım olan ikili mühasibat yazılışlarının verilməsi və
          müxtəlif mühasibat hesabatları ilə müxtəlif sahədə fəaliyyət göstərən
          şirkətlərin mühasibat uçotunun effektiv şəkildə aparılmasını təmin
          edir.
        </p>
        <li>
          <a href="https://v8.1c.ru/hrm/" target="_blank"
            >1C:Əmək haqqı və personalın idarə edilməsi</a
          >&nbsp;<RU /><OpenInNew />
        </li>

        <p>
          Konfiqurasiya şirkətlərin insan resurslarının idarə edilməsi üçün
          nəzərdə tutulub. Proqram kadr kargüzarlığının aparılması, məzuniyyət,
          icazə və xəstəliklər də nəzərə alınaraq əmək haqqının və onunla bağlı
          vergilərin avtomatik hesablanması, personala aid şəxsi və professional
          məlumatların qeydiyyatı və analizinə imkan verir.
        </p>
        <li>
          <a href="https://v8.1c.ru/trade/" target="_blank"
            >1C:Ticarətin idarə edilməsi</a
          >&nbsp;<RU /><OpenInNew />
        </li>

        <p>
          Bu həll ticarət müəssisələrində əməliyyat uçotunun aparılması üçün
          nəzərdə tutulub. Malların, anbar qalıqlarının və satışın detallı
          uçotu, qiymət və endirimlərin idarə edilməsi və geniş analiz imkanları
          sayəsində konfiqurasiya satış proseslərinin avtomatlaşdırılması və
          ticarət müəssisələrində operativ qərarların alınması üçün
          müvəffəqiyyətlə istifadə olunur.
        </p>
        <li>
          <a href="https://v8.1c.ru/retail/" target="_blank">1C:Pərakəndə</a
          >&nbsp;<RU /><OpenInNew />
        </li>

        <p>
          Pərakəndə satışın avtomatlaşdırılması üçün nəzərdə tutulan
          konfiqurasiya istənilən mağazanın satış prosesinə uyğunlaşdırıla
          bilər. Proqram geniş spektrdə ticarət avadanlığı ilə işləyə bilir.
          Konfiqurasiya 1C:Ticarətin idarə edilməsi və 1C:Mühasibat
          konfiqurasiyaları, digər 1C konfiqurasiyaları və digər
          texnologiyalarla işləyən bir çox mühasibat və əməliyyat uçotu proqramı
          ilə inteqrasiya imkanları sayəsində böyük müəssisələrdə də pərakəndə
          satış həlli olaraq müvəffəqiyyətlə tətbiq edilir.
        </p>
        <li>
          <a href="https://v8.1c.ru/small.biz/" target="_blank"
            >1C:Firmanın idarəedilməsi</a
          >&nbsp;<RU /><OpenInNew />
        </li>

        <p>
          Bu konfiqurasiyanın əsas təyinatı kiçik və orta ölçülü istehsalat və
          ticarət şirkətlərinin tam əməliyyat uçotunun qurulmasıdır. Proqramda
          mühasibat uçotu aparılmır və adətən 1C:Mühasibatla bərabər istifadə
          edilir.
        </p>
        <li>
          <a href="https://v8.1c.ru/ka/" target="_blank"
            >1C:Kompleks avtomatlaşdırma</a
          >&nbsp;<RU /><OpenInNew />
        </li>

        <p>
          Proqram əsasən orta ölçülü istehsalat müəssisələri üçün nəzərdə
          tutulub. Konfiqurasiyanın funksionalı 1C:Mühasibat, 1C:Ticarətin idarə
          edilməsi, 1C:Əmək haqqı və personalın idarə edilməsi
          konfiqurasiyalarının funksionalını özündə cəmləyir. Əlavə olaraq
          proqramda istehsalat uçotu, maliyyə büdcə planlaması və qismən
          istehsalatın planlanması imkanları var.
        </p>
        <li>
          <a href="https://v8.1c.ru/erp/" target="_blank"
            >1C:ERP Müəssisənin idarə edilməsi</a
          >&nbsp;<RU /><OpenInNew />
        </li>

        <p>
          Bu məhsul 1C-in ən geniş funksionala sahib konfiqurasiyasıdır.
          Proqram, fəaliyyət sahəsindən asılı olmayaraq böyük müəssisələrdə
          tətbiq edilmək üçün nəzərdə tutulub. Mühasibat və vergi uçotu, əmək
          haqqı, ticarət, anbar, istehsalat üzrə geniş imkanlara sahib olan
          konfiqurasiya mürəkkəb strukturlu şirkətlərin bütün fəaliyyətini
          avtomatlaşdırmaq üçün istifadə edilir.
        </p>
      </ul>
    </v-container>
    <contact-text text1="Tipik 1C həlləri üçün bizimlə " text2="saxlayın" />
  </div>
</template>

<script>
import OpenInNew from "@/ui/atoms/OpenInNew.vue";
import RU from "@/ui/atoms/Ru.vue";
import ContactText from "@/ui/atoms/ContactText.vue";

export default {
  components: { OpenInNew, RU, ContactText },
};
</script>

<style lang="scss" scoped>
@import "/src/styles/styles.scss";
.sub::before {
  display: block;
  content: " ";
  margin-top: -120px;
  height: 120px;
  visibility: hidden;
  pointer-events: none;
}
</style>

