<template>
  <div class="sub">
    <v-container class="txt-blc-con">      
      <div style="display: flex; justify-content: center">
        <v-card elevation="10">
          <v-img
            alt="Analiz xidmətləri"
            :src="require('/src/pages/Services/Analysis/Analysis.png')"
            class="pag-img"
          ></v-img
        ></v-card>
      </div>
      <br />
      <ul class="pag-ul">
        <p style="vertical-align: middle;">
          <img class="icn" :src="require('/src/assets/icons/prc.png')"/>      
          <txt-emp>Proses analizi</txt-emp></p>
        <p>
          çərçivəsində mövcud və yeni qurulan biznesin iş və verilən
          axışlarını detallı analiz edərək sizin ehtiyacınız olan informasiya
          sisteminin əsas verilən strukturu və nəzarət mexanizmlərini əhatə edən funksional tələblər hazırlayırıq
        </p>
        <p style="vertical-align: middle;">
          <img class="icn" :src="require('/src/assets/icons/sys.png')"/>      
          <txt-emp>Sistem analizi</txt-emp></p>
        <p>
          mövcud informasiya sisteminin iş və verilən axışlarında daxili ziddiyyət və biznesin
          tələblərinə uyğunsuzluğu aşkarlamaq məqsədi ilə aparılır. Analiz mövcud həllin texnolojik dəyərləndirməsini də əhatə edir
        </p>
        <p style="vertical-align: middle;">
          <img class="icn" :src="require('/src/assets/icons/per.png')"/>      
          <txt-emp>Personal analizi</txt-emp></p>
        <p>
          informasiya sistemi istifadəçilərinin kvalifikasiyasını dəyərləndirmək
          və yüksəltmə yollarını təyin etmək üçün aparılır
        </p>
        <p style="vertical-align: middle;">
          <img class="icn" :src="require('/src/assets/icons/dat.png')"/>      
          <txt-emp>Verilənlərin analizi</txt-emp></p>
        <p>
          vasitəsi ilə informasiya sisteminə daxil edilən verilənlərin
          sağlamlığına zərər verə biləcək amilləri ortaya çıxarır və daha doğru
          verilənlərə sahib olmaq üçün tövsiyyələr hazırlayırıq
        </p>
      </ul>
    </v-container>
    <contact-text />
  </div>
</template>

<script>
import OpenInNew from "@/ui/atoms/OpenInNew.vue";
import RU from "@/ui/atoms/Ru.vue";
import ContactText from "@/ui/atoms/ContactText.vue";

export default {
  components: { OpenInNew, RU, ContactText },
};
</script>

<style lang="scss" scoped>
@import "/src/styles/styles.scss";
.sub::before {
  display: block;
  content: " ";
  margin-top: -120px;
  height: 120px;
  visibility: hidden;
  pointer-events: none;
}
</style>

