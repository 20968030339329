<template>
  <v-container class="prd-crd-con pt-0">
    <br />
    <v-row align-content="center"
      ><v-col
        cols="12"
        lg="6"
        v-for="item in prodCards"
        :key="item.id"
        >
        <a class="txt-dec-non" :href="item.link">
          <div class="crd-ttl pb-3"> {{ item.title }}</div>
          <div class="sub-crd grow">{{ item.subtitle }}</div>
        
          <v-card elevation="5" class="mx-auto prd-crd">            
            <v-img
              :alt="item.title"
              contain
              class="crd-img"
              :src="require('/src/pages/Products/' + item.img)"
              rel="preload"
            ></v-img> </v-card
        ></a> </v-col
    ></v-row>
    <br />
    <contact-text
      text1="Məhsullar haqqında daha ətraflı məlumat üçün bizimlə "
      text2="saxlayın"
    />
  </v-container>
</template>

<script>
import ContactText from "@/ui/atoms/ContactText.vue";

export default {
  data() {
    return {
      prodCards: [      
        {
          id: 1,
          title: "egosoft veritas 3",
          subtitle: "Hər şey daxil asan biznes həlli",
          link: "/prod/veritas",
          img: "Veritas/veritas.png",
        },
        {
					id: 2,
					title: 'egoavto 2',
				  subtitle: 'Avtomobil servisinin idarəedilməsi üçün proqram təminatı',
          link: "/prod/egoavto",
          img: "egoavto/egoavto.png",
        },
        {
          id: 3,
          title: "1C: Müəssisə 8",
          subtitle: "1C firmasının tipik konfiqurasiyaları",
          link: "/prod/1C",
          img: "1C/1C.png",
        },
        {
          id: 4,
          title: "Skanport Data Mobile",
          subtitle: "Əl terminalları üçün universal həll",
          link: "/prod/datamobile",
          img: "DataMobile/DataMobile.png",
        },

					/*{
						id: 5,
						title: 'egosoft org.js',
						subtitle: 'Web əsaslı iş təqib sistemi',
						tag: 'org.js',
					},
					{
						id: 6,
						title: 'egosoft org 1C üçün',
						subtitle: '1C üçün iş təqib sistemi',
						tag: 'org.1c',
					}, */
      ],
    };
  },
  components: { ContactText },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/styles.scss";
.prd-crd-con {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 50px;
}

.crd-ttl {
  //background-color: whitesmoke;
  color: $blue;
  text-shadow: lightgray 2px 2px;
  font-weight: 800;
  text-align: center;
  font-size: x-large;
}
.crd-ttl:hover {
  //background-color: $red;
  color: $red;
  text-shadow: yellow 5px;
  
}

.crd-img {
  opacity: 0.8;
  transition: all 0.4s ease-in-out;
}
.crd-img:hover {
  opacity: 1;
}

.sub-crd {
  padding-bottom: 20px;
  text-align: center;
  font-size: medium;
  font-weight: 400;

  transition: all 0.4s ease-in-out;

}

.sub-crd:hover{
  transform: scale(1.1);
  font-weight: 800;
  color: $red;
} 

.prd-crd{
  max-width: 600px;
}
</style>
