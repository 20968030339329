<template>
	<sup>RU</sup>
</template>

<script>
	export default {};
</script>

<style lang="scss" scoped>
	sup {
		font-size: xx-small;
		font-weight: 800;
	}
</style>
