<template>
<div>
	<component :is="article"/>	
</div>
</template>

<!-- <script setup>
import { defineAsyncComponent } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const folder = route.params.folder;
console.log(folder);

const article = defineAsyncComponent(
	() => {
		const filePath = `./Folders/IS/01/01.long.az`;
		return import("" + filePath);
	}
); 

</script>
//import { onMounted, ref } from 'vue';
// import { useRoute } from 'vue-router' = req vue-router 4
-->

<script>
import { ref } from 'vue';
//import { router } from 'vue-router';

export default {
 	data () {
 		return {
				article: null,
				// folder: null,
				// number: null,
				// path: null
 		}
	},
	mounted () {
		const folder = "" + this.$route.params.folder;
		const number = "" + this.$route.params.number;
		const path = `./Folders/${folder}/${number}/${number}.long.az.vue`;
		try {
			const module = require("" + path);
			this.article = module.default;
		} catch (e)
		{
			window.location.href = "/404";
		}
  	},
	metaInfo: {
    title: `məqalələr`
  },
}

//const route = this.$route;
//const folder = route.params.folder;
//useRoute()

//onMounted(() => {
//	console.log(this.$route.params.folder);
	//article.value =
	//	() => import('./Articles/IS/01/01.long.az.vue');
//	});

</script>