<template>
  <v-container id="banners" class="ma-0 pa-0">
    <v-img
      :alt="title"
      class="b-img"
      :src="require('/src/assets/banners/' + imgName)"
      aspect-ratio="3"
      rel="prefetch"
    />
    <v-container fluid class="ma-0 pa-0 z-2 not-sel-text">
      <a :href="link"
        ><v-card tile color="primary" dark elevation="10" class="title">
          <v-card-title large
            ><h1>{{ title }}</h1>
            <v-spacer></v-spacer>
            <v-icon>mdi-cursor-default-click-outline</v-icon>
          </v-card-title>
          <v-card-subtitle class="mt-1"
            ><h2 style="white-space: nowrap">{{ subtitle }}</h2>
          </v-card-subtitle>
        </v-card></a
      >
    </v-container>
  </v-container>
</template>

<script>

export default {
  props: ["imgName", "title", "subtitle", "link"],
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";
@import "@/styles/styles.scss";

.title {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 90%;
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    width: 30%;
    min-width: 500px;
    margin-top: 5%;
    height: auto;
    font-size: xx-large;
  }
  @media #{map-get($display-breakpoints, 'md-only')} {
    width: 30%;
    margin-top: 2%;
    min-width: 400px;
    height: auto;
    font-size: xx-large;
  }
  @media #{map-get($display-breakpoints, 'sm-only')} {
    width: 30%;
    min-width: 350px;
    margin-top: 1%;
    height: auto;
    font-size: xx-large;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    position: relative;
    width: 100%;
    margin-top: 0;
    height: auto;
    font-size: xx-large;
    margin-bottom: 50px;
  }
}

.link-btn {
  color: $red !important;
}

.b-img {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    min-height: 200px;
  }
}
</style>
