<template>
  <div class="sub">
    <v-container class="txt-blc-con">
      <p class="txt-blc-hdr">İdarəetmə xidmətləri</p>
      <div style="display: flex; justify-content: center">
        <v-card elevation="10">
          <v-img
            alt="İdarəetmə xidmətləri"
            :src="require('/src/assets/banners/management.jpg')"
            class="pag-img"
          ></v-img
        ></v-card>
      </div>
      <br />
      <ul class="pag-ul">
        <h3>Layihə idarəetmə</h3>
        <p>
          Orta və böyük həcmli informasiya sistemlərinin yaradılması və işə
          salınması müxtəlif bilik və bacarıqlara sahib fərqli təşkilat və
          insanların koordinasiyası olmadan mümkün deyil. Layihələrin
          xüsusiyyətləri və tərəflərin imkanlarından asılı olaraq bu
          koordinasiyanı təmin edəcək layihə idarəetmə məsuliyyəti sifarişçi və
          ya icraçıya aid olmalı, ya da üçüncü tərəfə həvalə edilməlidir. Biz
          həm icraçısı olduğumuz işlərdə, həm də digər icraçıların layihələrində
          idarəetmə işlərini uğurla yerinə yetiririk.
        </p>
        <h3>Sistem idarəetmə</h3>
        <p>
          Mürəkkəb informasiya sistemlərinin idarəedilməsi ən az onun qurulması
          qədər çoxtərəfli kompetensiya tələb edir. Sistemin texniki
          infrastruktur, proqram funksionalı, istifadə praktikası,
          dəyişikliklərin idarəedilməsi, təhlükəsizlik, verilənlərin sağlamlığı
          kimi bütün ehtiyac duyulan məsələləri bir-biri ilə əlaqəli idarə
          edilməlidir. Bu məqsədlə biz öz sistem idarəetmə komandasını qurması
          üçün sifarişçilərimizə kömək edirik və ya sistemin idarəedilmə
          məsuliyyətini öz üzərimizə götürürük.
        </p>
        <h3>Personal idarəetmə</h3>
        <p>
          İnformasiya sisteminin effektivliyi onun istifadəçilərinin doğru
          seçilməsi, təcrübəsinin artırılması və proqramla işləmə
          praktikasına nəzarət edilməsindən asılıdır. Personalın idarəedilməsi
          və inkişafı xidmətləri ilə sistem personalımızın ehtiyaclarınıza
          uyğunluğunu təmin edirik.
        </p>
        <h3>Proqram idarəetmə</h3>
        <p>
          Çox sadə hallar xaricində müasir proqram təminatı daim inkişaf edən
          mürəkkəb texniki sistemdir. Bu sistemin funksional sağlamlığını və
          istifadə müddətini artırmaq üçün doğru stratejik texniki qərarların
          alınması və dəyişikliklərin idarəedilməsi xidməti ilə kömək edə
          bilərik.
        </p>
      </ul>
    </v-container>
    <contact-text />
  </div>
</template>

<script>
import OpenInNew from "@/ui/atoms/OpenInNew.vue";
import RU from "@/ui/atoms/Ru.vue";
import ContactText from "@/ui/atoms/ContactText.vue";

export default {
  components: { OpenInNew, RU, ContactText },
};
</script>

<style lang="scss" scoped>
@import "/src/styles/styles.scss";
.sub::before {
  display: block;
  content: " ";
  margin-top: -120px;
  height: 120px;
  visibility: hidden;
  pointer-events: none;
}
</style>

