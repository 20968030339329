import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"hom-txt-cnt"},[_c('h1',{staticClass:"hom-txt-h1"},[_c('span',{staticStyle:{"color":"darkred"}},[_vm._v("e")]),_vm._v("gosoft.az-a xoş gəldiniz"),_c('span',{staticStyle:{"color":"darkred"}},[_vm._v("!")])]),_c('br'),_c(VRow,{attrs:{"align-content":"center"}},_vm._l((_vm.homeCards),function(item){return _c(VCol,{key:item.id,staticClass:"d-flex flex-column srv-crd",attrs:{"cols":"12"}},[_c(VCard,{staticClass:"mx-auto flex",attrs:{"elevation":"0","width":"100%","height":"100%","min-width":"200px","max-width":"600px"}},[_c(VCardTitle,{staticClass:"hom-crd-ttl"},[_vm._v(_vm._s(item.title)+" ")]),_c(VCardText,{staticClass:"pt-5 blu-txt"},[_vm._v(_vm._s(item.text))])],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }