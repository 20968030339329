<template>
  <div class="blk">
    <v-container class="we-cnt">
      <v-card>
        <div class="we-txt">
          <h1>Biz kimik?</h1>
          <h2>Biz İnformasiya Sistemləri ekspertiyik</h2>
          <br />
          <p>
            <strong><red>e</red>gosoft</strong>
            2010-cu ildən kiçik, orta və böyük biznesin kompüterləşmiş
            informasiya sistemlərinin planlanması, yaradılması və dəstəyi ilə
            xidmətinizdədir.
          </p>
          <p>
            <strong>İnformasiya sistemi</strong> &ndash; biznesin ölçülməsi və
            idarəedilməsi məqsədi ilə məlumatların yığılması, saxlanması və
            analizi üçün istifadə edilən metodologiya, texniki vəsait və insan
            resursları kompleksidir. Biz əsas işimizin informasiya sisteminin
            bir bütöv olaraq biznesə faydalı olmasını təmin etmək olduğunu
            düşünürük.
          </p>
          <p>
            Ciddi <strong>metodolojik</strong> təcrübəmiz sayəsində müxtəlif
            biznes sahələrində iş və məlumat axışlarını düzgün analiz edə
            bilirik. Məlumatların biznesin ehtiyaclarına uyğun formatda
            saxlanması və emalı üçün informasiya sistemlərini layihələndiririk.
          </p>
          <p>
            <strong>Texnolojik</strong> biliklərimiz biznes analizinə uyğun
            müxtəlif masaüstü, web və mobil həllər yaratmağımıza imkan verir.
          </p>
        </div></v-card
      >
    </v-container>
  </div>
</template>

<script>
import Red from "@/ui/atoms/Red.vue";

export default {
  components: { Red },
};
</script>

<style lang="scss" scoped>
@import "@/styles/styles.scss";
.we-cnt {
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  max-width: 600px;
}
.we-txt {
  padding: 10px;
  background-color: whitesmoke;
  color: $blue;
}
.we-txt h1 {
  color: $red;
}
.we-txt h2 {
  color: $blue;
}
</style>
