import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"blg-crd-con"},[_c(VRow,{attrs:{"align-content":"center"}},_vm._l((_vm.articleCards),function(item){return _c(VCol,{key:item.id,staticClass:"d-flex flex-column",attrs:{"cols":"12","sm":"6","md":"6","lg":"6","xl":"6"}},[_c(VCard,{attrs:{"elevation":"5","width":"100%","height":"100%"}},[_c(VCardTitle,{staticClass:"blg-crd-ttl"},[_vm._v(_vm._s(item.title))]),_c(VCardSubtitle,[_vm._v("#"+_vm._s(item.series)+"-"+_vm._s(item.number))]),_c(VCardText,{staticClass:"blg-txt",domProps:{"innerHTML":_vm._s(_vm.htmlContent(item))}})],1),_c('a',{staticClass:"blg-mor",attrs:{"href":item.link}},[_vm._v("daha ətraflı...")])],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }