<template v-slot:default>
  <span class="red-txt"><slot></slot></span>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/styles/styles.scss";
.red-txt {
  color: $red;
}
</style>
