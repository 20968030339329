<template>
  <v-container>
    <main-banners />
    <home-text />
    <contact-text
      text1="Biznesinizə proqram həlləri ilə necə faydalı ola biləcəyimizi öyrənmək üçün bizimlə "
      text2=" saxlayın"
    />
  </v-container>
</template>

<script>
import MainBanners from "@/ui/components/MainBanners";
import ContactText from "@/ui/atoms/ContactText.vue";
import HomeText from "@/pages/Home/HomeText.vue";

export default {
  name: "Home",
  metaInfo: {
    title: "əsas",
    meta: [
      {
        name: "description",
        content:
          "İnformasiya Sistemlərinin planlanması, yaradılması və dəstəyi",
      },
    ],
  },
  data() {
    return {};
  },
  components: {
    MainBanners,
    ContactText,
    HomeText,
  },
};
</script>

<style lang="scss">
.wave {
  animation-name: wave-animation;
  animation-duration: 2.5s;
  animation-iteration-count: 1;
  transform-origin: 70% 70%;
  display: inline-block;
  opacity: 0;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
    opacity: 0;
  }
  10% {
    transform: rotate(14deg);
    opacity: 0.2;
  }
  20% {
    transform: rotate(-8deg);
    opacity: 0.4;
  }
  30% {
    transform: rotate(14deg);
    opacity: 0.6;
  }
  40% {
    transform: rotate(-4deg);
    opacity: 0.8;
  }
  50% {
    transform: rotate(10deg);
    opacity: 1;
  }
  60% {
    transform: rotate(0deg);
    opacity: 0;
  }
  100% {
    transform: rotate(0deg);
    opacity: 0;
  }
}
</style>