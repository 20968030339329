<template>
  <v-container class="hom-txt-cnt">
    <h1 class="hom-txt-h1"><span style="color:darkred">e</span>gosoft.az-a xoş gəldiniz<span style="color:darkred">!</span></h1>
    <br />
    <v-row align-content="center"
      ><v-col
        cols="12"
        class="d-flex flex-column srv-crd"
        v-for="item in homeCards"
        :key="item.id"
      >
        <v-card
          
          elevation="0"
          width="100%"
          height="100%"
          class="mx-auto flex"
          min-width="200px"
          max-width="600px"
        >
          <v-card-title class="hom-crd-ttl">{{ item.title }} </v-card-title>
          <v-card-text class="pt-5 blu-txt">{{ item.text }}</v-card-text>
        </v-card>
      </v-col></v-row
    >
  </v-container>
</template>

<script>
import ContactText from "@/ui/atoms/ContactText.vue";

export default {
  data() {
    return {
      homeCards: [
        {
          id: 0,
          title:
            "İnformasiya Sisteminin yaradılması və tətbiqi vasitəsi ilə biznesin rəqəmsal transformasiyası",
          text: "egosoft olaraq biz texnologiyanın inkişaf və innovasiyalar üçün təkan olduğuna inanırıq. Biz biznes üçün proqram təminatının yaradılması və informasiya sisteminin qurulması, işə salınması və dəstəyi ilə şirkətlərin rəqəmsal transformasiya hədəflərinə çatmalarına kömək edirik. Biznesə uyğunlaşdırılmış proqram təminatı və rahat tətbiq metodologiyaları təcrübəmizlə biz müştərilərimizə işlərinin effektivliyini artırma və biznes haqqında operativ və dolğun məlumat alma imkanı verərək uğurlarına dəstək oluruq.",
        },
        {
          id: 1,
          title:
            "Uyğunlaşdırılmış Proqram Təminatı: unikal ehtiyaclarınız üçün düşünülmüş həllər",
          text: "Hər biznes unikaldır, və hazır proqram həlləri çox vaxt spesifik tələblərə cavab verə bilmir. egosoft sizin biznes hədəfləriniz və proseslərinizə tam uyğunlaşdırılmış proqram təminatı üzrə ixtisaslaşıb. Təcrübəli analitik və proqramçılardan ibarət komandamız unikal ehtiyac və istəklərinizi analiz edir, yaradıcı proqram həllərini layihələndirmək, yaratmaq və quraşdırmaq üçün bütün bilik və bacarıqlarından istifadə edir. Ehtiyacınız nə olursa olsun, mürəkkəb ERP sistemi, geniş CRM platforması, avtomatik mühasibat proqramı və ya əməliyyatların idarəolunması sistemi; mükəmməl həllər ortaya çıxaracaq təcrübəyə sahibik.",
        },
        {
          id: 2,
          title: "Rəvan Keçid: Keçid və adaptasiyanın hamarlığını təmin edirik",
          text: "Çox zaman yeni proqram təminatının tətbiqi biznes üçün çox ağrılı prosesdir. Biz hamar keçidin və istifadəçilərin adaptasiyasının nə qədər vacib olduğunu çox yaxşı başa düşürük. Tətbiq mütəxəssislərimiz müştəri komandası ilə bərabər işləyərək proqram təminatının mövcud infrastruktura tətbiqi prosesinin daha rəvan keçməsini təmin edirlər. Tətbiq addımlarını diqqətlə planlaşdırır və kəsintiləri minimallaşdırmaq üçün əlimizdən gələni edirik. Hərtərəfli təlim və canlı dəstək işçilərinizin yeni sistemin bütün potensialından istifadə edə bilməsini təmin edəcək bilik və bacarıqlara sahib olmasına imkan verir.",
        },
        {
          id: 3,
          title:
            "Geniş Biznes Təcrübəsi: müxtəlif sektorlarda proqram təminatı həlləri",
          text: "Uzun illərdir müxtəlif sektorlarda fəaliyyət göstərən şirkətlərin spesifik tələblərini dərindən öyrənirik. İstehsalat, maliyyə, pərakəndə satış, market, avtomobil servisi, mağazalar zənciri, və ya hər hansı başqa sektorda çalışan təşkilatlar üçün onların unikal məsələləri üçün xüsusi həllər təklif edəcək təcrübəyə sahibik. Komandamız biznes bilikləri ilə texniki qabiliyyətlərimizi birləşdirərək iş axışlarını qaydaya salan, məhsuldarlığı artıran və müştəri məmnuniyyətini yüksəldən proqram həlləri təklif edir. Son tendensiyalar və texnologiyaları izləyərək müştərilərimizə daha öndə olmalarını təmin edən həllər veririk.",
        },
        {
          id: 4,
          title: "Öncə Keyfiyyət: Hər zaman öncəliklə ən yüksək iş keyfiyyəti",
          text: "egosoft olaraq ən yüksək keyfiyyət standartlarına cavab verən proqram həlləri təmin etmək əzmindəyik. Sektorda tövsiyə olunan yollar və keyfiyyət tələblərinə uyğun işləyən komandamız hər layihənin yüksək tələblərə cavab verməsini təmin edir. Müştəri yönümlü yanaşmamız sayəsində proqram təminatının yaradılması və tətbiqi prosesində sizi hər addımda məlumatlandıraraq şəffaf ünsiyyət qururuq.",
        },
        {
          id: 5,
          title: "Rəqəmsal Keçid yolunda egosoftla bərabər addımlayın",
          text: "Rəqəmsal transformasiyaya hazırsınızsa, egosoft bu yolda sizin etibarlı tərəfdaşınız olacaq. Ekspertlərdən ibarət komandamızın məqsədi texnologiyalar vasitəsi ilə biznesinizin inkişafına kömək edərək sizin üçün yeni dəyər yaratmaqdır. Konsepsiyadan işə salmaya qədər hər addımda biz yaradıcı proqram həlləri və mükəmməl dəstəklə müştərilərimizin yanında oluruq.",
        },
      ],
    };
  },
  components: { ContactText },
  mounted() {},
  methods: {},
};
</script>


<style lang="scss">
@import "@/styles/styles.scss";

.hom-txt-cnt {
  text-align: center;
  white-space: nowrap;
}

.hom-txt-h1 {
  color: $blue;
  animation-name: fade-in;
  animation-duration: 3s;
  animation-iteration-count: 1;
}

.hom-crd-ttl {
  word-break: normal;
  background: $blue;
  color: white;
  text-align: center !important;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>