<template>
  <div class="logo-tmp">
    <v-container id="logo-con" class="inf">
      <v-row>
        <v-col>
          <v-chip class="num" x-large label outlined>
            <span id="prj"></span
          ></v-chip>
          <br /> </v-col
      ></v-row>
      <div class="lbl">layihədə</div>
      <div>xidmət göstərdik</div>
      <br />
    </v-container>
    <v-container class="logo-img-con">
      <span v-for="img in imgs"
        :key="img">
        <v-img class="logo-img"         
          aspect-ratio="1.67"
          :src="require('/src/assets/logos/' + img + '.png')"
          :alt="img"
          rel="preload"
        >
        </v-img>
      </span>
    </v-container>
    <!-- <v-row id="logos">
      <v-col
        class="logo"
        align-self="center"
        cols="6"
        sm="4"
        v-for="img in imgs"
        :key="img"
      > -->        
      <!-- </v-col>
    </v-row> -->
  
  </div>
</template>

<script>
export default {
  data() {
    return {
      projects: 183,
      imgs: [
        "Melissa Group",
        "Performance Center",        
        "Group Motors",

        "Hidromek",
        "Kaessbohrer",
        "Promhimperm",

        "Optimal",
        "Music Gallery",
        "Connect",

        "Sinteks",
        "BAT",
        "Metro",

        "PTI",

        "DebetSafety",
        "TopServis",
        "ISCO",
        
        "Arti Group",
        "Motor House",
        "GEN",
        "Yeni Masazir",
        "Neoclass",
        "Collezione Italia",
        "NewCond",

        "Lezard",
        "Bine Aqro",
        "MKT",
        "Unicapital",
        "Radius",
        "Seba",
        "Tac",
        "Bertling",
        "Debenhams",
        "WomenSecret",
        "WHSmith",
        "DNS",
      ],
    };
  },
  methods: {
    Init() {
      this.CountUp("prj", 0, this.projects, 2000 / this.projects);
    },
    CountUp(id, from, to, delay) {
      from++;
      if (from <= to) {
        try {
          document.getElementById(id).innerHTML = from;
          setTimeout(this.CountUp, delay, id, from, to, delay);
        } catch (e) { }
      }
    },
  },
  mounted() {
    this.Init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/styles.scss";

.logo-img-con{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 600px;
}

.logo-img{
  flex: 1 1 20px;
  background: teal;
  color: white;
  width: auto;
  padding: 50px;
  margin: 10px;
  aspect-ratio: 1.67;
}

.logo-img:hover {
  transform: scale(2);
  z-index: 9;
}

#logo-con {
  //display: flex;
  //flex-wrap: wrap;
  //justify-content: center;
  
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 50px;
}

#logos {
  padding-left: 0;
  padding-right: 0;
  max-width: 600px;
  margin: auto;
}

.logo {
  padding: 5px;
  border: 1px solid lightgrey;
  //opacity: 20%;
}
.logo:hover {
  opacity: 100%;
}

.logo-tmp {
  padding-bottom: 50px;
}
</style>
