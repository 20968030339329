<template>
<div class="pt-0 mt-0">
<article-cards/>
</div>
</template>

<script>
import ArticleCards from "@/pages/Articles/ArticleCards.vue";

export default {
	components: { ArticleCards },
	metaInfo: {
    title: "məqalələr",
    meta: [
      {
        name: "description",
        content:
          "Məqalələrimiz informasiya sistemlərini daha yaxşı anlamanız və onların doğru istifadəsi üçün sizə faydalı olacaq",
      },
    ],
  },
}
</script>