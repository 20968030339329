<template>
  <div class="ts-tmp">
    <v-container class="ts-con inf">
      <span
        ><v-row>
          <v-col>
            <v-chip class="num" x-large label outlined>
              <span id="y">0</span></v-chip
            >
            <div class="xs"></div>
            <v-chip x-large color="white">
              <span class="lbl">il</span></v-chip
            ></v-col
          >
          <v-col>
            <v-chip class="num" x-large label outlined
              ><span id="m">0</span></v-chip
            >
            <div class="xs"></div>
            <v-chip x-large color="white">
              <span class="lbl">ay</span></v-chip
            ></v-col
          ><v-col>
            <v-chip class="num" x-large label outlined>
              <span id="d">0</span></v-chip
            >
            <div class="xs"></div>
            <v-chip x-large color="white">
              <span class="lbl">gün</span></v-chip
            ></v-col
          ></v-row
        ></span
      >
      <br />
      <v-card
        ><v-parallax
          alt="-"
          height="300"
          :src="require('/src/assets/bg/whbl.jpg')"
          aspect-ratio="3"
        >
          <div id="time-txt">
            biznes üçün informasiya sistemlərinin planlanması, yaradılması və
            dəstəyi ilə xidmətinizdəyik
          </div></v-parallax
        ></v-card
      >
    </v-container>
  </div>
</template>

<script>
export default {
  components: {},
  mounted() {
    this.Init();
  },
  methods: {
    Init() {
      let TimeSince = this.TimeSince(new Date(2010, 2, 3));

      this.CountUp("y", 0, TimeSince.y, 1000 / TimeSince.y);
      this.CountUp("m", 0, TimeSince.m, 1000 / TimeSince.m);
      this.CountUp("d", 0, TimeSince.d, 1000 / TimeSince.d);
    },
    TimeSince(date) {
      let now = new Date();
      let y = now.getFullYear() - date.getFullYear();

      let m1 = date.getMonth();
      let m2 = now.getMonth();

      let m = m2 - m1;
      if (m2 < m1) {
        y--;
        m += 12;
      }

      let d1 = date.getDate();
      let d2 = now.getDate();
      let d = d2 - d1;
      if (d2 < d1) {
        m--;
        d += this.daysInLastMonth();
      }

      return { y: y, m: m, d: d };
    },
    daysInLastMonth() {
      let tm = new Date();
      let y = tm.getFullYear();
      let m = tm.getMonth();
      if (m == 0) {
        m = 12;
        y--;
      }
      let d = new Date(y, m, 0).getDate();
      return d;
    },
    CountUp(id, from, to, delay) {
      from++;
      if (from <= to) {
        try {
          document.getElementById(id).innerHTML = from;
          setTimeout(this.CountUp, delay, id, from, to, delay);
        } catch (e) { }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/styles.scss";

.ts-con {
  padding-top: 50px; /*   padding-left: 10px;
  padding-right: 10px;
  
 */
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .xs {
    display: none;
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .xs {
    display: block;
  }
}

#time-txt {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: x-large;
  text-align: center;
  font-weight: 800;
  color: $blue;
  text-shadow: lightgray 1px 1px;
}

.ts-tmp {
  padding-bottom: 50px;
}
</style>
