<template>
  <span class="con-txt phone-num">
    <span class="zon-cod">&nbsp;+994</span
    ><span>&nbsp;(10)&nbsp;525&dash;0010&nbsp;</span></span
  >
</template>

<style lang="scss" scoped>
@import "@/styles/styles.scss";

.zon-cod {
  font-size: small;
}

.phone-num {
  position: relative;
  top: 0px;
  left: 0px;

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    display: inline;
  }

  @media #{map-get($display-breakpoints, 'md-only')} {
    display: inline;
  }

  @media #{map-get($display-breakpoints, 'sm-only')} {
    position: absolute;
    top: -30px;
    left: -10px;
    width: 600px;
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    display: none;
  }
}
</style>

<script>
export default {};
</script>