import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"ph not-sel-text"},[_c(VRow,{staticClass:"ma-0",attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"2","sm":"4"}},[(_vm.prevPage.path)?_c('router-link',{staticClass:"ntd pn",attrs:{"to":_vm.prevPage.path}},[_c(VBtn,{staticClass:"cr",attrs:{"fab":"","small":""}},[_c(VIcon,[_vm._v("mdi-chevron-left")])],1),_c('span',{staticClass:"ma-2 nav-text"},[_vm._v(_vm._s(_vm.prevPage.title))])],1):_vm._e()],1),_c(VCol,{staticClass:"pa-0",attrs:{"cols":"8","sm":"4"}},[(_vm.pageLink)?_c('router-link',{staticClass:"no-u",attrs:{"to":_vm.pageLink}},[_c(VSheet,{staticClass:"pt-2 pb-2 pl-5 pr-5 pt"},[_vm._v(_vm._s(_vm.pageTitle))])],1):_vm._e()],1),_c(VCol,{staticClass:"text-right pa-0",attrs:{"cols":"2","sm":"4"}},[(_vm.nextPage.path)?_c('router-link',{staticClass:"ntd pn",attrs:{"to":_vm.nextPage.path}},[_c('span',{staticClass:"ma-2 nav-text"},[_vm._v(_vm._s(_vm.nextPage.title))]),_c(VBtn,{staticClass:"cr",attrs:{"fab":"","small":""}},[_c(VIcon,[_vm._v("mdi-chevron-right")])],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }