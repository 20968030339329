<template>
  <v-container pa-0>
    <v-carousel
      class="ma-0 pa-0"
      v-model="mainBanner"
      id="main-banner"
      height="auto"
      continuous
      cycle
      interval="14000"
      progress
    >  
      <v-carousel-item v-for="banner in this.banners" :key="banner.no">
        <banner
          :imgName="banner.imgName"
          :title="banner.title"
          :subtitle="banner.subtitle"
          :link="banner.link"
        >
        </banner>
        <TextFade v-if="banner.textFade">{{ banner.text }}</TextFade>
        <ArrayText
          v-if="banner.arrayText"
          :no="banner.no"
          :text2="banner.text2"
          :CONTENT="banner.textArray"
          :textArrayClass="banner.textArrayClass"
        ></ArrayText>
      </v-carousel-item> </v-carousel
  ></v-container>
</template>

<script>
import Banner from "@/ui/blocks/Banner.vue";
import TextFade from "@/ui/atoms/TextFade.vue";
import ArrayText from "@/ui/blocks/ArrayText.vue";

export default {
  name: "MainBanner",
  components: {
    Banner,
    TextFade,
    ArrayText,
  },
  data: () => {
    return {
      mainBanner: 0,
      banners: [
         {
           no: 1,
          imgName: "IS_Outsourcing.jpg",
           title: "IS Outsourcing",
           subtitle: "Sizin İnformasiya Sistemləri şöbəniz",
           textFade: true,
           arrayText: false,
           text: "İnformasiya Sisteminin dizaynı, qurulması, inkişafı və dəstəyi üçün hər şey",
           text2: "",
           textArray: [],
           textArrayClass: "",
           link: "srv/ISOutsourcing",
         },
        {
          no: 2,
          imgName: "analysis.jpg",
          title: "Analiz xidmətləri",
          subtitle: "Geniş kompetensiya, sistematik araşdırma",
          textFade: false,
          arrayText: true,
          text: "",
          text2: "analiz edirik",
          textArray: ["prosesləri", "sistemləri", "personalı", "verilənləri"],
          textArrayClass: "con-l",
          link: "srv/Analysis",
        },
        {
          no: 3,
          imgName: "egosoft-veritas.jpg",
          title: "egosoft veritas",
          subtitle: "Asan və funksional universal uçot proqramı",
          textFade: false,
          arrayText: true,
          text: "",
          text2: "",
          textArray: [
            "Alış",
            "Anbar",
            "Satış",
            "İstehsalat",
            "Maliyyə",
            "Kadr uçotu",
            "Əmək haqqı",
            "Mühasibat",
          ],
          textArrayClass: "con-lb20",
          link: "prod/veritas",
        },
        {
          no: 4,
          imgName: "management.jpg",
          title: "İdarəetmə xidmətləri",
          subtitle: "Məsuliyyətli və professional idarəetmə",
          textFade: false,
          arrayText: true,
          text: "",
          text2: "idarə edirik",
          textArray: ["layihələri", "sistemləri", "personalı", "proqramları"],
          textArrayClass: "con-rt",
          link: "srv/management",
        },
        {
          no: 5,
          imgName: "programming.jpg",
          title: "Proqramlaşdırma",
          subtitle: "Doğru texnologiyalar və keyfiyyətli kod",
          textFade: false,
          arrayText: true,
          text: "",
          text2: "ilə proqramlaşdırırıq",
          textArray: ["C#", "Dart", "1C", "Node JS", "php"],
          textArrayClass: "con-c",
          link: "srv/programming",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
#main-banner h1 {
  text-shadow: 1px 1px grey;
}
#main-banner a {
  text-decoration: unset;
}

#main-banner {
  top: -36px;
}
</style>
