<template>
  <div class="sub">
    <v-container class="txt-blc-con">
      <div style="display: flex; justify-content: center">
        <v-card elevation="10">
          <v-img
            alt="Proqramlaşdırma xidmətləri"
            :src="require('/src/pages/Services/Programming/Programming.png')"
            class="pag-img"
          ></v-img
        ></v-card>
      </div>
      <br />
      <ul class="pag-ul">
        <p>
Müasir rəqəmsal dövrdə ölçüsündən və sahəsindən asılı olmayaraq bütün bizneslər əməliyyatlarını optimallaşdırmaq, məhsuldarlığı artırmaq və böyümək üçün proqram təminatı həllərindən istifadə edir. Amma heç də bütün təşkilatların öz proqram təminatını yaratmaq və dəstəkləmək üçün resurs və təcrübəsi yoxdur. Bu hallarda <strong>proqramlaşdırma xidmətləri</strong> təqdim edən şirkətlər köməyə gəlir.  
</p><p>
Müştərilərimizin ehtiyaclarını qarşılaya bilmək üçün müxtəlif metodologiya və texnologiyalar əsasında proqramlaşdırma xidmətləri göstəririk. Ən çox məşğul olduğumuz proqramlaşdırma xidməti biznesin ehtiyacına uyğun əməliyyatların idarəedilməsi və mühasibat üçün <strong>korporativ sistemlər</strong>in yaradılmasıdır. Belə sistemlərin qurulması üçün müştərilərimizin əlaqəli şöbələri ilə birlikdə biznesin informasiya sistemləri tələblərini aşkarlayıb onlara uyğun unikal həllər təqdim edirik. </p>
<p>Proqramlaşdırma işlərini texniki ehtiyaclar və mövcud sistemləri nəzərə alaraq yerinə yetiririk. Əsas istifadə etdiyimiz <a href="https://www.egosoft.az/tech">texnologiyalar</a> .NET, 1C, NodeJS və PHP-dir.
</p>
<p>Eyni zamanda proqramlaşdırma komandamız öz <a href="https://www.egosoft.az/prod">məhsullar</a>ımızın yaradılması ilə də məşğul olur.</p>
      </ul>
    </v-container>
    <contact-text />
  </div>
</template>

<script>
import OpenInNew from "@/ui/atoms/OpenInNew.vue";
import RU from "@/ui/atoms/Ru.vue";
import ContactText from "@/ui/atoms/ContactText.vue";

export default {
  components: { OpenInNew, RU, ContactText },
};
</script>

<style lang="scss" scoped>
@import "/src/styles/styles.scss";
.sub::before {
  display: block;
  content: " ";
  margin-top: -120px;
  height: 120px;
  visibility: hidden;
  pointer-events: none;
}
</style>

