import Vue from 'vue';
import store from '@/store/index.js';
import VueRouter from 'vue-router';
import Home from '@/pages/Home/Home.vue';
import We from '@/pages/We/We.vue';
import Products from '@/pages/Products/Products.vue';
import Services from '@/pages/Services/Services.vue';
import Technologies from '@/pages/Technologies/Technologies.vue';
import Contact from '@/pages/Contact.vue';
import _1C from '@/pages/Products/1C/1C.vue';
import veritas from '@/pages/Products/Veritas/veritas.vue';
import egoavto from '@/pages/Products/egoavto/egoavto.vue';
import DataMobile from '@/pages/Products/DataMobile/DataMobile.vue';
import PageNotFound from '@/pages/PageNotFound.vue';
import ISOutsourcing from '@/pages/Services/ISOutsourcing/ISOutsourcing.vue';
import Analysis from '@/pages/Services/Analysis/Analysis.vue';
import Management from '@/pages/Services/Management.vue';
import Programming from '@/pages/Services/Programming/Programming.vue';
import Articles from '@/pages/Articles/Articles.vue';
import Article from '@/pages/Articles/Article.vue'

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,

	},
	{
		path: '/we',
		name: 'We',
		component: We,

	},
	// {
	// 	path: '/we/li-01',
	// 	name: 'We',
	// 	component: We,
	// 	redirect: '/we'
	// },	
	{
		path: '/prod',
		name: 'Products',
		component: Products,

	},
	{
		path: '/prod/1C',
		name: '_1C',
		component: _1C,

	},
	{
		path: '/prod/veritas',
		name: 'veritas',
		component: veritas,
	},
	{
		path: '/prod/egoavto',
		name: 'egoavto',
		component: egoavto,
	},	// {
	// 	path: '/prod/veritas/li-01',
	// 	name: 'veritas',
	// 	component: veritas,
	// 	redirect:'/prod/veritas',
	// },
	{
		path: '/prod/DataMobile',
		name: 'DataMobile',
		component: DataMobile,	
	},
	{
		path: '/srv',
		name: 'Services',
		component: Services,

	},
	{
		path: '/srv/ISOutsourcing',
		name: 'ISOutsourcing',
		component: ISOutsourcing
	},
	// {
	// 	path: '/srv/ISOutsourcing/li-01',
	// 	name: 'ISOutsourcing',
	// 	component: ISOutsourcing,
	// 	redirect:'/srv/ISOutsourcing'
	// },
	{
		path: '/srv/analysis',
		name: 'Analysis',
		component: Analysis,
	},
	// {
	// 	path: '/srv/analysis/li-01',
	// 	name: 'Analysis',
	// 	component: Analysis,
	// 	redirect:'/srv/analysis'
	// },
	{
		path: '/srv/management',
		name: 'Management',
		component: Management,

	},
	// {
	// 	path: '/srv/management/li-01',
	// 	name: 'Management',
	// 	component: Management,
	// 	redirect:'/srv/management',
	// },
	{
		path: '/srv/programming',
		name: 'Programming',
		component: Programming,
	},
	// {
	// 	path: '/srv/programming/li-01',
	// 	name: 'Programming',
	// 	component: Programming,
	// 	redirect: '/srv/programming'
	// },
	{
		path: '/tech',
		name: 'Technologies',
		component: Technologies,

	},
	{
		path: '/articles',
		name: 'Articles',
		component: Articles
	},
	{
		path: '/articles/:folder/:number',
		name: 'Article',
		component: Article
	},
	{
		path: '/contact',
		name: 'Contact',
		component: Contact,

	},
	{
		path: '/*',
		name: 'PageNotFound',
		component: PageNotFound,			
	},
];

const router = new VueRouter({
	routes,
	mode: 'history',
	scrollBehavior (to, from, savedPosition) {
		return { x: 0, y: 0 };
	}
});

router.beforeEach((to, from, next) => {
	setMenuItemFromTo(to, next);
});


function setMenuItemFromTo (to, next) {
	let selectedItem = -1;
	if (to.name != 'PageNotFound') {		
	
		let path = to.path;

		let i = path.indexOf('/', 1);
		let path_l = path;
		if (i > 1) {
			path_l = path.substring(0, i);
		}
		
		store.state.menu.every((x) => {
			if (x.path == path_l) {
				selectedItem = x.id;
				return false;
			} else {
				return true;
			}
		});
	};

	store.state.selectedItem = selectedItem;
	
	next();
}

export default router;
