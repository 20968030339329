<template>
  <v-container :class="textArrayClass" :id="No('ArrTxtCon')">
    <v-sheet class="ArrTxt" height="1em" :id="No('ArrTxt')"
      ><span>{{ No("") }}</span></v-sheet
    ><br />
    <v-sheet class="ArrTxt2" :id="No('ArrTxt2-')">{{ text2 }}</v-sheet>
  </v-container>
</template>

<script>
export default {
  props: ["no", "text2", "CONTENT", "textArrayClass"],
  data: () => {
    return {
      TYPE_TIME: 100,
      DELETE_TIME: 50,
      NEXT_TIME: 300,
      DELETE_PAUSE: 1000,

      TXT1: null,
      INTERVAL_AN: null,
      //CONTENT: ['prosesləri', 'sistemləri', 'personalı', 'verilənləri'],
      SENTENCE: 0,
      LETTER: 0,
    };
  },
  mounted() {
    this.Init();
    this.INTERVAL_AN = setInterval(this.Type, this.TYPE_TIME);
  },
  methods: {
    Init() {
      this.TXT1 = document.querySelector(this.No("#ArrTxt"));
      this.TXT1.innerHTML = "init";
    },
    No(name) {
      return "" + name + this.no;
    },
    Type() {
      var self = this;
      var text1 = this.CONTENT[this.SENTENCE].substring(0, this.LETTER + 1);
      this.TXT1.innerHTML = text1;
      this.LETTER++;

      // If full sentence has been displayed then start to delete the sentence after some time
      if (text1 === this.CONTENT[this.SENTENCE]) {
        clearInterval(this.INTERVAL_AN);

        setTimeout(function () {
          self.INTERVAL_AN = setInterval(() => {
            self.DeleteText();
          }, self.DELETE_TIME);
        }, self.DELETE_PAUSE);
      }
    },
    DeleteText() {
      var self = this;
      var text1 = this.CONTENT[this.SENTENCE].substring(0, this.LETTER - 1);
      this.TXT1.innerHTML = text1;
      this.LETTER--;

      // If sentence has been deleted then start to display the next sentence
      if (text1 === "") {
        clearInterval(this.INTERVAL_AN);
        this.INTERVAL_AN = null;
        // If last sentence then display the first one, else move to the next
        if (this.SENTENCE == this.CONTENT.length - 1) {
          this.SENTENCE = 0;
        } else this.SENTENCE++;
        this.LETTER = 0;

        // Start to display the next sentence after some time
        setTimeout(function () {
          self.INTERVAL_AN = setInterval(self.Type, self.TYPE_TIME);
        }, self.NEXT_TIME);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/styles.scss";

.con-l {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    position: absolute;
    left: 20px;
    top: 50%;
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    position: absolute;
    left: 30%;
    top: 20px;
  }
}
.con-lb {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    position: absolute;
    left: 20px;
    bottom: 0;
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    position: absolute;
    left: 20px;
    bottom: 50%;
  }
}

.con-lb20 {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    position: absolute;
    left: 20px;
    bottom: 20px;
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    position: absolute;
    left: 20px;
    top: 40%;
  }
}

.con-c {
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    height: 30%;
  }
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    position: absolute;
    //left: 5%;
    top: 20%;
    text-align: center;
    //background-color: white;
    //opacity: 80%;
    //width: 90%;
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    position: absolute;
    //left: 5%;
    top: 10%;
    text-align: center;
    //background-color: white;
    //opacity: 80%;
    //width: 90%;
  }
}

.ArrTxt {
  font-weight: 600;
  color: $blue !important;
  background-color: transparent !important;
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    font-size: xx-large;
  }
  @media #{map-get($display-breakpoints, 'md-only')} {
    font-size: x-large;
  }
  @media #{map-get($display-breakpoints, 'sm-only')} {
    font-size: xx-large;
  }
  text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white,
    1px 1px 0 white;
}

.ArrTxt2 {
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    font-size: xx-large;
  }
  @media #{map-get($display-breakpoints, 'md-only')} {
    font-size: x-large;
  }
  @media #{map-get($display-breakpoints, 'sm-only')} {
    font-size: xx-large;
  }
  color: $red !important;
  background-color: transparent !important;
  text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white,
    1px 1px 0 white;
}
</style>
