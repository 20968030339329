<template>
  <div class="pt-5 mt-0">
    <page-subtitle
      >İnformasiya Sisteminiz üçün lazım olan bütün xidmətlər</page-subtitle
    >
    <service-cards />
    <page-space />
  </div>
</template>

<script>
import ServiceCards from "@/pages/Services/ServiceCards.vue";
import PageSpace from "@/ui/atoms/PageSpace.vue";
import PageSubtitle from "@/ui/atoms/PageSubtitle.vue";

export default {
  name: "Services",
  metaInfo: {
    title: "xidmətlər",
    meta: [
      {
        name: "description",
        content: "İnformasiya Sisteminiz üçün lazım olan bütün xidmətlər",
      },
    ],
  },

  components: {
    ServiceCards,
    PageSpace,
    PageSubtitle,
  },
  mounted() {},
  methods: {},
};
</script>