import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"prd-crd-con pt-0"},[_c('br'),_c(VRow,{attrs:{"align-content":"center"}},_vm._l((_vm.prodCards),function(item){return _c(VCol,{key:item.id,attrs:{"cols":"12","lg":"6"}},[_c('a',{staticClass:"txt-dec-non",attrs:{"href":item.link}},[_c('div',{staticClass:"crd-ttl pb-3"},[_vm._v(" "+_vm._s(item.title))]),_c('div',{staticClass:"sub-crd grow"},[_vm._v(_vm._s(item.subtitle))]),_c(VCard,{staticClass:"mx-auto prd-crd",attrs:{"elevation":"5"}},[_c(VImg,{staticClass:"crd-img",attrs:{"alt":item.title,"contain":"","src":require('/src/pages/Products/' + item.img),"rel":"preload"}})],1)],1)])}),1),_c('br'),_c('contact-text',{attrs:{"text1":"Məhsullar haqqında daha ətraflı məlumat üçün bizimlə ","text2":"saxlayın"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }