<template>
	<span>
	&copy; {{ year() }} egosoft mmc<br />
	</span>
</template>

<script>
export default
  {
    methods: {
      year: function() {
        let d = new Date();
        return d.getFullYear();
      },
  }  
}
</script>

