import Vue from 'vue';
//import Vuetify from 'vuetify/lib/framework';
import Vuetify from 'vuetify';
import az from 'vuetify/lib/locale/az';

Vue.use(Vuetify);
const opts = {
	lang: {
		locales: { az },
		current: 'az',
	},
	customProperties: true,
	breakpoint: {
		thresholds: {
			xs: 700,
			sm: 960,
			md: 1264,
			lg: 1904,
			/* 			xs: 340,
			sm: 540,
			md: 800,
			lg: 1280,
 */ //xl: 1900,
		},
	},
	theme: {
		themes: {
			light: {
				primary: '#930606',
				secondary: '042658',
				accent: '#8c9eff',
				error: '#b71c1c',
			},
			dark: {
				primary: '#930606',
				secondary: '042658',
				accent: '#8c9eff',
				error: '#b71c1c',
			},
		},
	},
	icons: {
    	iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  	}
};

export default new Vuetify(opts);
