import { render, staticRenderFns } from "./ArrayText.vue?vue&type=template&id=9e1b3406"
import script from "./ArrayText.vue?vue&type=script&lang=js"
export * from "./ArrayText.vue?vue&type=script&lang=js"
import style0 from "./ArrayText.vue?vue&type=style&index=0&id=9e1b3406&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports