import { render, staticRenderFns } from "./ContactButton.vue?vue&type=template&id=d6680d08&scoped=true"
var script = {}
import style0 from "./ContactButton.vue?vue&type=style&index=0&id=d6680d08&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6680d08",
  null
  
)

export default component.exports