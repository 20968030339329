<template>
	<v-container class="blg-crd-con">		
		<v-row align-content="center">
			<v-col cols="12" sm="6" md="6" lg="6" xl="6" 
			class="d-flex flex-column"
			 v-for="item in articleCards" :key="item.id">
		<v-card elevation="5" width="100%" height="100%">
			<v-card-title class="blg-crd-ttl">{{item.title}}</v-card-title>
			<v-card-subtitle>#{{item.series}}-{{item.number}}</v-card-subtitle>
			<v-card-text class="blg-txt" v-html="htmlContent(item)"/>
		</v-card><a :href="item.link" class="blg-mor">daha ətraflı...</a></v-col>
		</v-row>
	</v-container>
</template>

<style lang="scss" scoped>
@import "@/styles/styles.scss";
.blg-crd-con{
	padding: 30px;
	padding-top: 50px !important;	
}
.blg-crd-ttl{
	color: $red;
	font-weight: 800;
}

.blg-txt{
	color: $blue !important;
}

.blg-crd-act{
	//position: absolute;
	bottom:0;
	//height: 100px	;
}
.blg-mor{
	color: $red;
	font-weight: 400;
	text-align: center;
}
</style>

<script>

export default {

	data () {
		return {		
			path: "./Folders/",
			articleCards: [
				{
					id: 1,
					title: "İnformasiya Sistemi nədir",
					subfolder: "IS/01/",
					shortURL: "01.short.az.html",
					series: "IS",
					number: 1,
					img: "01.az.png",					
					long: "01.long.az.vue",
					link: "/articles/IS/01",
				},]}
		},
	methods: {	
		htmlContent (item) {
			var shortURL = this.path + item.subfolder + item.shortURL;
			var html = require(""+shortURL).default;
			return html;		
		},
		itemImg (item) {
			var URL = this.path + item.subfolder + item.img;
			return require(""+URL);
		}
	},
	mounted () {
	},
	computed: {

	}
}

</script>