<script setup>
  const prods = [
    { id: 0, title: "DataMobile", imgUri: "DM.png", comp: "DM" },
    { id: 1, title: "DM. Əsas vəsaitlər", imgUri: "FA.png", comp: "FA"},
    { id: 2, title: "DM. Qiymət yoxlama", imgUri: "PC.png", comp: "PC" },
    { id: 3, title: "DM. Çatdırma", imgUri: "DD.png",comp: "DD" },
    { id: 4, title: "DM. Mobil Ticarət", imgUri: "MT.png", comp: "MT" },
  ];
import DM from "./DM.vue";
import FA from "./FA.vue";
import PC from "./PC.vue";
import DD from "./DD.vue";
import MT from "./MT.vue";
</script>

<template>
  <div>  
    <p class="mx-6">
    Scanport şirkətinin DataMobile mobil proqram həlləri anbar, satış, istehsalat və logistika sahələrinin avtomatlaşdırılması üçün nəzərdə tutulub. 
    Proqram həlləri Android əməliyyat sistemli mobil terminallarda barkod və RFID vasitəsi ilə mal və inventarın qalıq və hərəkətlərinin qeydiyyatını aparmaq imkanı verir.
    Proqramların əsas funksionalı aşağıdakılardan ibarətdir:
    <ul>
      <li>malların identifikasiyası</li>
      <li>malların qəbulu, verilməsi və yerdəyişməsi</li>
      <li>sayım</li>
      <li>sifarişlərin yığılması</li>
      <li>rəflər üzrə qalıq</li>
      <li>qiymət yoxlama</li>
      <li>qiymət dəyişdirmə</li>
    </ul>
    </p>  
      <v-row v-for="prod in prods" :key="prod.id">        
        <v-col>
        <v-card flat>
          <v-card-title class="justify-center">            
            <v-img class="dm-prd-img" :src="require(`/src/pages/Products/DataMobile/${prod.imgUri}`)">              
            </v-img>
        </v-card-title>
        <v-card-actions class="justify-center"><h3>{{ prod.title }}</h3>
        </v-card-actions>
        </v-card>
        <br>
        <d-m v-if="prod.comp=='DM'"></d-m>
        <f-a v-if="prod.comp=='FA'"></f-a>
        <p-c v-if="prod.comp=='PC'"></p-c>
        <d-d v-if="prod.comp=='DD'"></d-d>
        <m-t v-if="prod.comp=='MT'"></m-t>
        </v-col>
      </v-row>
  </div>
</template>

<style lang="scss" scoped>
@import "/src/styles/styles.scss";

.dm-img
{
    max-width: 50px;     
}

.dm-prd-img
{
  max-width: 150px;
}

.dm-ttl
{
  font-size: medium;
}


</style>