<template v-slot:default>
  <div class="pag-sub"><slot></slot></div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/styles/styles.scss";

.pag-sub {
  text-align: center;
  font-size: large;
  font-weight: 400;
  padding-top: 1em;
  text-shadow: 0 0 2px gray;
  margin-left: 10%;
  margin-right: 10%;
}
</style>
