<template>
  <div class="nb-con">
    <router-link v-if="nextPage.title" class="no-uln" :to="nextPage.path">
      <v-icon class="nav-icn" x-large>mdi-chevron-double-down</v-icon>
      <v-sheet class="nb-txt">{{ nextPage.title }}</v-sheet>
    </router-link>
  </div>
</template>

<style lang="scss" scoped>
@import "@/styles/styles.scss";

.nb-con {
  position: relative;

  background-color: white;
  opacity: 0.5;

  text-align: center;
}

.no-uln {
  text-decoration-line: none;
}

.nb-txt {
  color: $blue;
  font-size: large;
  font-weight: 800;
}

.nav-icn {
  color: $red !important;
}

.nav-icn:hover {
  color: $red !important;
}
</style>

<script>
export default {
  methods: {
    up() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    down() {
      window.scrollTo(0, document.body.scrollHeight);
    },
  },
  computed: {
    nextPage() {
      let res = {};
      let i = this.$store.state.selectedItem;
      if (i < this.$store.state.menu.length - 1) {
        res.title = this.$store.state.menu[i + 1].title[this.$store.state.lang];
        res.path = this.$store.state.menu[i + 1].path + "";
      }
      return res;
    },
    prevPage() {
      let res = { path: "" };
      let i = this.$store.state.selectedItem;
      if (i == null) {
        i = 0;
      }
      if (i > 0) {
        res.title = this.$store.state.menu[i - 1].title[this.$store.state.lang];
        res.path = this.$store.state.menu[i - 1].path;
      }
      return res;
    },
  },
};
</script>
