<template>
	<v-container class="blg-art">
		<h2 class="ser-hdr">İnformasiya sistemləri seriyası #01</h2>
		<hr>
		<h1 class="pag-hdr">İnformasiya sistemləri nədir</h1>
		<br>

<p>Bu yazı İnformasiya sistemləri (İS) sahəsində paylaşmağı planladığımız silsilənin ilk addımıdır. Fikrimizcə əsasən biznes sahibləri, əməliyyat, maliyyə, informasiya texnologiyaları və satınalma rəhbərləri üçün faydalı ola biləcək qısa məqalələrin məqsədi biznes üçün informasiya sistemlərinin yaradılması və dəstəyi barədə doğruluğuna inandığımız bilik və təcrübəmizi bölüşmək, qərarverici şəxslərə sıx-sıx rastlaşdığımız informasiya sistemlərinə aid yanlışların qarşısını almağa kömək etmək, informasiya sistemləri barədə müxtəlif biznes sahələrini maraqlandıran aktual mövzulara dair praktik məlumatlar verməkdir.
Paylaşmalarımızı əvvəlcədən düşünülmüş bir axışa uyğun kiçik məqalələr şəklində planlaşdırırıq. Amma məqalənin mövzusundan asılı olmayaraq informasiya sistemləri haqqında sizi maraqlandıran sualları şərhlərdə yaza bilərsiniz, əlimizdən gəldiyi qədər bütün sualları cavablandırmağa çalışacağıq.</p>

<br>
<h3>İnformasiya sisteminin əsas hissələri</h3>
<br>

<p>İnformasiya sistemləri bəzən yersiz sadələşdirilərək yalnız proqram təminatı kimi başa düşülür, halbuki sistemi yaradan digər komponentləri və onların bir-birinə təsirini nəzərə almamaq çox riskli yanaşmadır. Bu məqalədə xüsusilə biznes təyinatlı informasiya sistemlərinin əsas tərkib hissələri və onlar arasındakı münasibətlər haqqında qısaca danışmaq istəyirik. 
</p>
<p>Ümumiləşdirərək informasiya sistemlərinin əsas 5 hissədən ibarət olduğunu qeyd edə bilərik:</p>
<div class="art-img-con">
	<img class="art-img" src="./01.az.png"/>
</div>

<br>
	<div class="art-icn-con">
		<img class="art-icn" src="./workflow.png"/>
		<h3>İş və məlumat axışı sxemləri</h3></div>
<br>

<p>Mürəkkəb informasiya sistemlərinin dizaynında mütləq iş və məlumat axışı sxemlərindən istifadə edilir. Bu sxemlər işlərin kim tərəfindən, hansı sıra ilə, hansı şərtlərə uyğun olaraq və hansı məlumatları qeyd edərək aparılmasını təyin edir. Praktikada ölkəmizdə İS quruluşunda ən çox “yaddan çıxan” hissənin iş axışı analizi və dizaynı olduğunu deyə bilərik. Başa düşmək lazımdır ki, bu barədə xüsusilə düşünsəniz də, iş axışını tamamilə nəzərə almasanız da reallıqda həmişə bir iş axışı var. İstifadə etdiyiniz informasiya sisteminin iş axışları ya planlı, ya da spontan şəkildə aşağıda göstərilən hər hansı bir yolla formalaşır:</p>
<ul>
<li>Biznes analitikləri tərəfindən xüsusi hazırlanmış iş axışı sxemləri:</li> mürəkkəb, qeyri-standart biznes və ya informasiya sistemləri üçün mütləq şərtdir; sadə və standart biznes üçün adətən yersiz xərcdir; arada qalan “orta” seqmentdə adətən “adi” iş axışı barədə tərəflərin konsensusu olur, yalnız qeyri-standart prosesləri analiz edərək vaxt və pula qənaət etmək mümkündür;
<li>Proqram təminatının faktiki iş axışı çərçivələri:</li> seçdiyiniz proqram təminatının standart iş axışlarına uyğunlaşmaq bir çox halda ən asan və doğru həldir. Biz hazır proqram tətbiq etdiyimiz zaman adətən bir müddət proqrama dəyişiklik etmədən işlətməyi məsləhət görürük, bir çox halda başlanğıcda yaranan dəyişiklik tələbləri bu müddət ərzində qüvvədən düşür;
<li>İnzibati şəkildə təyin edilən iş axışı:</li> istənilən proqram təminatının istifadəsi zamanı müəyyən dərəcədə texnolojik olmayan inzibati məhdudiyyətlər və məcburiyyətlər də təyin edilir, şifahi və yazılı təlimatlarla təmin edilən bu cür inzibati çərçivəni mümkün olduğu qədər texnolojik metodlarla avtomatlaşdırmaq faydalıdır. Amma demək olar ki, bütün faydalı praktikalar kimi burada da fanatizmdən qaçmaq lazımdır: “mümkün olduğu qədər” “həmişə” deyil;
<li>İstifadəçilərin özlərinin formalaşdırdığı iş axışı:</li> təəssüf ki, heç görmək istəmədiyimiz, amma çox rastlaşdığımız praktikadır; nə professional analitiklər, nə proqram təminatı istehsalçısı, nə də idarəedicilər tərəfindən təyin edilmiş prinsip və qaydalar deyil, İS hər bir istifadəçinin konkret vəziyyətdə özü üçün rahat saydığı metodla işləyir. Bu informasiya sistemində məlumatların keyfiyyəini azaldır, bəzi hallarda sistemi yararsız hala gətirir
</ul>
<br>
	
<div class="art-icn-con"><img class="art-icn" src="./software.png"/><h3>Proqram təminatı</h3></div>
<br>

<p>Proqram təminatı, sözsüz, informasiya sisteminin ən vacib hissəsidir. İdeal halda proqram təminatı ya sizin üçün professionalca hazırlanmış iş axışına uyğun olaraq yaradılıb, ya da siz öz biznesinizi böyük ölçüdə hazır proqram təminatının nəzərdə tutduğu iş axışına uyğunlaşdıra bilirsiniz. Çox sadə hallar xaric, proqram təminatının funksionalının dəyişə bilən olması çox əhəmiyyətlidir. Çünki</p>
	<ul>
		<li>Heç bir proqram təminatı 100% sizin istəklərinizə uyğun olmaz</li>
<li>Sizin ehtiyaclarınız dəyişə bilər</li>
<li>Qanunvericilik və biznes mühiti dəyişə bilər</li></ul><br>
<p>Sağlam informasiya sisteminə sahib olmaq üçün doğru proqram təminatının doğru şəkildə qurulması yetərli deyil, əksər hallarda proqram təminatının baxımı (işlək vəziyyətdə olmasını təmin etmək və ehtiyac olduğu təqdirdə bərpa etmək) və inkişafı (funksionalın dəyişdirilməsi, əlavə funksionalın yaradılması, səhvlərin aradan qaldırılması) üçün daimi texniki dəstəyə ehtiyac var.</p>
<div class="art-icn-con"><img class="art-icn" src="./infrastructure.png"/><h3>İnfrastruktur</h3></div>

<br><p>Hər bir informasiya sistemi bir IT infrastrukturunda işləyir. Sadə halda bu bir kompüter, hətta bir telefon; mürəkkəb halda müxtəlif nöqtələrdə yerləşən serverlər və müxtəlif şəbəkə avadanlığı deməkdir. İnfrastrukturun təyinatı proqram təminatı funksionalını və informasiya sistemində olan  məlumatlarla istifadəçilər arasında kəsintisiz, təhlükəsiz və sürətli məlumat axışını təmin etməkdir. İnzibati, texniki, mali və, ən əsası, insan resursları üzrə yetərincə imkanınız varsa öz İT strukturunuzu qura bilərsiniz, əks halda kənar infrastruktur sistemlərinin xidmətlərindən istifadə etmək daha doğru yoldur.</p> 
<div class="art-icn-con"><img class="art-icn" src="./users.png"/><h3>İstifadəçilər</h3></div>
<br>
<p>Müasir informasiya sistemləri mümkün olduğu qədər istifadəçiləri doğru yönləndirməyə və ciddi səhvlər etməsinin qarşını almağa çalışır, amma yenə də istifadəçilərin bacarıq və bilikləri sistemin sağlamlığına çox təsir göstərir. Doğru bacarıqlara sahib istifadəçilərə sahib olmaq və onları lazım olan şəkildə bilikləndirmək informasiya sisteminin doğru istifadəsi üçün şərtdir.</p>  
<div class="art-icn-con"><img class="art-icn" src="./database.png"/><h3>Məlumat bazası</h3></div>
<br><p>İdeal informasiya sistemində istifadəçilər tərəfindən daxil edilən və ya proqram təminatının formalaşdırdığı məlumatlar başa düşülməsi, axtarılması və qruplaşdırılması asan olacaq şəkildə saxlanmalıdır. Bu da iş axışlarının, proqram təminatının, istifadəçilərin və istifadə edilən məlumat bazası texnologiyalarının doğru olması ilə mümkündür. Ciddi məlumat bazasına sahib bizneslər periodik olaraq məlumatların sağlamlığını araşdırmalı, məlumat kirliliyini, səhv verilənləri aradan qaldırmalı və məlumat keyfiyyətinin artırılması üçün inzibati və texniki tədbirlər almalıdır.</p>
<div class="art-icn-con"><img class="art-icn" src="./star.png"/><h3>6-cı element</h3></div>
<br>
<p>Fransız filosofu Michel Serres <code>[mişel ser]</code> bir müsahibəsində Misirdə Asuan bəndi layihəsi üçün qurulan komitədə hidravlika mühəndisləri, müxtəlif materiallar üzrə ekspertlər, tikinti mütəxəssisləri və hətta ekoloqlar olsa da, nə bir filosof, nə də bir misirşünas olmadığından şikayətlənir. Jurnalist "misirşünas olmaması həqiqətən doğru deyil, amma belə bir komitədə filosofa niyə ehtiyac var ki?” deyə soruşur. Serres bu suala çox təəccüblənərək “o, komitədə misirşünas olmadığını görərdi” cavabını verir.
Çox sadə hallar xaric, informasiya sistemlərinin planlanması, qurulması, və dəstəyi müxtəlif peşə sahiblərinin fərqli metodologiya və texnologiyalardan istifadəsini tələb edir. Sistemin mürəkkəblik dərəcəsi artdıqca Serres tərəfindən filosof adlandırılan və ümumi xətləri ilə bütün mənzərəni görə bilən bir insana ehtiyac da artır. İS layihələrində ya sifarişçi, ya icraçı, ya da 3-cü tərəf bir “filosof” yoxdursa, bəzi şeylərin gözdən qaçma ehtimalı yüksəkdir.</p> 
	</v-container>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import "@/styles/styles.scss";

.blg-art{
	@media #{map-get($display-breakpoints, 'sm-and-up')} {
	padding-left:50px !important;
	padding-right:30px !important;
  	}
	@media #{map-get($display-breakpoints, 'xs-only')} {
	padding-left:10px !important;
	padding-right:5px !important;
   	}
	
}

.ser-hdr{
	color: $red;
}

.pag-hdr{
	color: $blue;
}
	.art-img-con{
		margin-top: 50px;
		margin-bottom: 50px;
		text-align: center;
	}

	.art-img{
	  	@media #{map-get($display-breakpoints, 'sm-and-up')} {
    		width: 600px;
  		}
  		@media #{map-get($display-breakpoints, 'xs-only')} {
    		width: 250px;
		}
	}

	.art-icn-con{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.art-icn{		
		width: 80px;
		padding-right: 20px;
	}
	
	hr{
		margin-bottom: 15px;
	}
</style>