<template>
  <div class="pt-0 mt-0">
    <time-since></time-since>
    <customer-map></customer-map>
    <logos></logos>
    <expertise></expertise>
    <we-text></we-text>
    <we-action></we-action>
    <page-space />
  </div>
</template>

<script>
import TimeSince from "@/pages/We/TimeSince.vue";
import Logos from "@/pages/We/Logos.vue";
import CustomerMap from "@/pages/We/CustomerMap.vue";
import Expertise from "@/pages/We/Expertise.vue";
import WeAction from "@/pages/We/WeAction.vue";
import WeText from "@/pages/We/WeText.vue";
import PageSpace from "@/ui/atoms/PageSpace.vue";

export default {
  components: {
    TimeSince,
    Logos,
    CustomerMap,
    Expertise,
    WeAction,
    WeText,
    PageSpace,
  },
  name: "We",
  metaInfo: {
    title: "biz",
    meta: [
      {
        name: "description",
        content:
          "Geniş İnformasiya Sistemləri təcrübəmizlə sizin üçün də faydalı ola bilərik",
      },
    ],
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/styles.scss";

#we-crd {
  max-width: 500px;
}
</style>
