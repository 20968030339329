import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"ma-0 pa-0",attrs:{"id":"banners"}},[_c(VImg,{staticClass:"b-img",attrs:{"alt":_vm.title,"src":require('/src/assets/banners/' + _vm.imgName),"aspect-ratio":"3","rel":"prefetch"}}),_c(VContainer,{staticClass:"ma-0 pa-0 z-2 not-sel-text",attrs:{"fluid":""}},[_c('a',{attrs:{"href":_vm.link}},[_c(VCard,{staticClass:"title",attrs:{"tile":"","color":"primary","dark":"","elevation":"10"}},[_c(VCardTitle,{attrs:{"large":""}},[_c('h1',[_vm._v(_vm._s(_vm.title))]),_c(VSpacer),_c(VIcon,[_vm._v("mdi-cursor-default-click-outline")])],1),_c(VCardSubtitle,{staticClass:"mt-1"},[_c('h2',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.subtitle))])])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }