<template>
  <v-container class="srv-crd-con">    
    <v-row align-content="center"
      ><v-col cols="12" sm="6" md="6" lg="6" xl="6"
        class="d-flex flex-column srv-crd"
        v-for="item in srvCards"
        :key="item.id"
      >
        <v-card
          elevation="5"
          width="100%"
          height="100%"
          class="mx-auto flex"
          min-width="200px"
          max-width="600px"
        >
          <a :href="item.link" class="txt-dec-non"
            ><v-card-title class="srv-crd-ttl pb-5 justify-center">
              <v-btn fab class="srv-crd-icn-btn">
                <v-icon x-large class="srv-crd-icn"
                  >{{ item.icon }}
                </v-icon></v-btn
              ></v-card-title
            ><v-card-title class="srv-crd-ttl pb-5 justify-center">
              {{ item.title }}
            </v-card-title>
            <v-card-text class="pt-2 srv-crd-txt pt-5">
              {{ item.text }}</v-card-text
            >
          </a></v-card
        >
      </v-col></v-row
    >
    <br />
    <contact-text />
  </v-container>
</template>

<script>
import ContactText from "@/ui/atoms/ContactText.vue";

export default {
  data() {
    return {
      srvCards: [
        {
          id: 0,
          title: "Analiz",
          text: "İnformasiya sistemlərinin qurulması və təkmilləşdirilməsi üçün biznesinizi analiz edirik",
          icon: "mdi-merge",
          link: "srv/Analysis",
        },
        {
          id: 1,
          title: "Proqramlaşdırma",
          text: ".Net, 1C, Node.js və php ilə ehtiyacınıza uyğun sistemlərin yaradılması üçün proqramlaşdırma işləri görürük",
          icon: "mdi-code-json",
          link: "srv/Programming",
        },
        {
          id: 2,
          title: "İdarəetmə",
          text: "Layihə, sistem, personal və proqramların idarəedilməsini təmin edirik",
          icon: "mdi-map-plus",
          link: "srv/Management",
        },
        {
          id: 3,
          title: "IS Outsourcing",
          text: "Sizin İnformasiya Sistemləri şöbənizi əvəz edirik",
          icon: "mdi-package-variant-closed",
          link: "srv/ISOutsourcing",
        },
      ],
    };
  },
  components: { ContactText },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/styles.scss";

.srv-crd-con {
  padding-top: 50px;
  padding-left: 10%;
  padding-right: 10%;
}

.srv-crd {
  background-color: white;
  color: $blue;
}

.srv-crd:hover {
  background-color: lightyellow;
}

.srv-crd-ttl {
  text-shadow: lightgray 2px 2px;
  font-weight: 800;
}

.srv-crd-ttl:hover {
  background-color: $red;
  color: white;
  text-shadow: none;
  font-weight: 800;
}
.srv-crd-icn {
  color: $red;
  text-shadow: 2px 2px 20px gray;
}

.srv-crd-icn-btn {
  color: $red;
}

.srv-crd-txt {
  color: $blue;
  font-size: large;
  text-align: center;
}

.srv-crd-txt:hover {
  color: $red;
}
</style>
