<template>
  <div class="map-tmp">
    <v-container id="map-con" class="inf">
      <v-container class="inf">
        <v-row>
          <v-col>
            <v-chip class="num" x-large label outlined>
              <span id="cnt"></span
            ></v-chip>
            <br /> </v-col
        ></v-row>
        <div class="lbl">ölkədə</div>
        <br />
      </v-container>
      <v-container id="map-img-con">
        <v-card id="map-card" class="pa-2">
          <v-img
            alt="Xəritə"
            id="map"
            class="map"
            :src="require('/src/assets/map.png')"
            :lazy-src="require('/src/assets/map.png')"
            rel="preload"
          />
        </v-card>
      </v-container>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      countries: 5,
    };
  },
  methods: {
    Init() {
      this.CountUp("cnt", 0, this.countries, 1000 / this.countries);
      this.MapAnime();
    },
    CountUp(id, from, to, delay) {
      from++;
      if (from <= to) {
        try {
          document.getElementById(id).innerHTML = from;
          setTimeout(this.CountUp, delay, id, from, to, delay);
        } catch (e) { }
      }
    },
    MapAnime() {
      var opac = 100;
      var up = true;
      var elMap = document.getElementById("map");
      let intMap = setInterval(() => {
        if (opac == 100 || opac == 70) {
          up = !up;
        }
        elMap.style.opacity = opac / 100;
        if (up) {
          opac += 1;
        } else {
          opac -= 1;
        }
      }, 150);
    },
  },
  mounted() {
    this.Init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/styles.scss";

#map-con {
  /*   padding-left: 10%;
  padding-right: 10%;
 */
  padding-top: 50px;
}
.map {
  max-width: 600px;
  max-height: 600px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

#map-img-con {
  width: 600px;
  padding: 0;
}

#map-card {
  background-color: whitesmoke;
  padding: 0 !important;
}

.map-tmp {
  background-color: whitesmoke;
  padding-bottom: 50px;
}
</style>

