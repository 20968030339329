import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		path: '/',
		lang: 'az',
		menu: [
			{
				id: 0,
				title: { az: 'Əsas', ru: 'Главная', en: 'Home', tr: 'Ana sayfa' },
				path: '/',
			},
			{
				id: 1,
				title: { az: 'Biz', ru: 'Мы', en: 'We', tr: 'Biz' },
				path: '/we',
			},
			{
				id: 2,
				title: {
					az: 'Məhsullar',
					ru: 'Продукты',
					en: 'Products',
					tr: 'Ürünler',
				},
				path: '/prod',
			},
			{
				id: 3,
				title: {
					az: 'Xidmətlər',
					ru: 'Услуги',
					en: 'Services',
					tr: 'Hizmetler',
				},
				path: '/srv',
			},

			{
				id: 4,
				title: {
					az: 'Texnologiyalar',
					ru: 'Технологии',
					en: 'Technologies',
					tr: 'Teknolojiler',
				},
				path: '/tech',
			},
			{
				id: 5,
				title: {
					az: 'Məqalələr',
					ru: 'Статьи',
					en: 'Articles',
					tr: 'Makaleler',
				},
				path: '/articles',
			}, 
			{
				id: 6,
				title: {
					az: 'Əlaqə',
					ru: 'Контакты',
					en: 'Contact',
					tr: 'İletişim',
				},
				path: '/contact',
			}, 
		],
		selectedItem: -1,
		hamburgerMenu: null,
	},
	mutations: {
		toggleHM(state) {
			state.hamburgerMenu = !state.hamburgerMenu;
		},
	},
	actions: {
		toggleHM(context) {
			context.commit('toggleHM');
		},
	},
	getters: {
		nextPage: function (state) {
      		let res = {};
			let i = state.selectedItem;
			if (i < state.menu.length - 1) {
				res.title = state.menu[i + 1].title[state.lang];
				res.path = state.menu[i + 1].path + "";
      		}
      		return res;			
		},
		currentPage: function (state) {
      		let res = {};
			let i = state.selectedItem;
			if (i < state.menu.length-1) {
				res.title = state.menu[i].title[state.lang];
				res.path = state.menu[i].path + "";
      		}
      		return res;						
		}
	}
	,
	modules: {},
});
