import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@mdi/font/css/materialdesignicons.css';
import VueMeta from 'vue-meta';

Vue.config.productionTip = false

Vue.use(VueMeta, {
});
 new Vue({
  vuetify,
  router,
  store,
  render: function (h) {
    return h(App)
  }
}).$mount('#app')

