import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map-tmp"},[_c(VContainer,{staticClass:"inf",attrs:{"id":"map-con"}},[_c(VContainer,{staticClass:"inf"},[_c(VRow,[_c(VCol,[_c(VChip,{staticClass:"num",attrs:{"x-large":"","label":"","outlined":""}},[_c('span',{attrs:{"id":"cnt"}})]),_c('br')],1)],1),_c('div',{staticClass:"lbl"},[_vm._v("ölkədə")]),_c('br')],1),_c(VContainer,{attrs:{"id":"map-img-con"}},[_c(VCard,{staticClass:"pa-2",attrs:{"id":"map-card"}},[_c(VImg,{staticClass:"map",attrs:{"alt":"Xəritə","id":"map","src":require('/src/assets/map.png'),"lazy-src":require('/src/assets/map.png'),"rel":"preload"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }